import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { Descriptions, Divider, Switch } from 'antd'
import moment from 'moment'

import VerificationListTasksTable from '../VerificationLists/partials/VerificationListTasksTable'
import SendNotificationButton from '../../components/SendNotificationButton'

import { notifyNonCompliantReport } from '../NonCompliantReports/services'

const { Item } = Descriptions

export default function NonCompliantTabOffline({ service }) {
	const [verificationTasks, setVerificationTasks] = useState(null)
	const [onlyRequired, setOnlyRequired] = useState(false)

	const nonCompliantReport = service.service_inspector.service_plan.service_review.non_compliant_report || null
	const verificationList =  service.verification_list

	useEffect(() => {
		!verificationTasks && (
			setVerificationTasks(service.verification_list.verification_tasks.filter(task => (
				((task.is_compliant === 0 && task.is_active === 1) || task.observations_retry) &&
				(!onlyRequired || task.is_require === 1)
			)))
		)
	}, [ verificationTasks, verificationList, service, onlyRequired ])

	return (
		<>
			<h6 className='mt-10 mb-0 text-center'>Reporte de No Conformidades</h6>
			<Descriptions bordered size="small" className='mt-15' column={2}>
				<Item label="Versión">{nonCompliantReport.format_version.sku}</Item>
				<Item label="Fecha Vigencia">
					{moment(nonCompliantReport.format_version.expire_at).format('DD-MMM-YYYY').toUpperCase()}
				</Item>
			</Descriptions>
			<p className='bold mt-15'>TAREAS REGISTRADAS NO CONFORMES</p>
			<div className='mb-10 float-right'>
				<Switch 
					checked={onlyRequired} 
					onChange={()=>{
						setOnlyRequired(!onlyRequired)
						setVerificationTasks(null)
					}} 
				/>
				<span className='ml-5'>Mostrar solo requeridas</span>
			</div>
			<VerificationListTasksTable
				verificationList={verificationList}
				verificationTasks={verificationTasks}
				reloadTasks={() => setVerificationTasks(null)}
				updateTasks={() => setVerificationTasks(null)}
				editable={service.initial_service_id ? true : false}
				isRetry={service.initial_service_id ? true : false}
				serviceId={service.id}
			/>
			<Link to={`/offline/non_compliant_reports/preview/${service.id}/${nonCompliantReport.id}`}>
				<Button color="primary" outline>Formato Reporte De No Conformidades</Button>
			</Link>
			<Divider type="vertical" />
			<SendNotificationButton 
				notifyService={() => notifyNonCompliantReport(nonCompliantReport.id)} 
				offlineRequest={{method: 'POST', url: `/non_compliant_reports/${nonCompliantReport.id}/notify`}}
			/>
		</>
	)
}