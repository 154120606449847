import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import TaskTemplatesTable from './partials/TaskTemplatesTable'
import NewTaskTemplateModal from './partials/NewTaskTemplateModal'
import InternetRequired from '../Offline/partials/InternetRequired'

import { getTaskTemplates } from './services'
import { can, handleError } from '../../helpers'

export default function TaskTemplatesList() {
	const { isConnected } = useSelector(state => state.offline)
	const [taskTemplates, setTaskTemplates] = useState(null)
	const [filterType, setFilterType] = useState('description')
	const [filterValue, setFilterValue] = useState('')
	const [showNewTemplateModal, setShowNewTemplateModal] = useState(false)

	useEffect(() => {
		!taskTemplates && isConnected && can('task_templates.viewAll') && getTaskTemplates({
			[`filter[${filterType}]`]: filterValue,
			include: 'instalationTypes'
		})
			.then(res => setTaskTemplates(res.data.data))
			.catch(error => handleError(error))
	}, [ taskTemplates, filterType, filterValue, isConnected ])

	if(!isConnected) return <InternetRequired />

	return (
		<>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title="Lista de Verificación Maestra"
				subtitle="Plantillas de tareas de listas de verificación"
				options={[
					{ label: 'Buscar por descripción', value: 'description' },
					{ label: 'Buscar por ítem', value: 'sku' },
				]}
				reload={() => setTaskTemplates(null)}
				ctaText="Agregar Plantilla"
				handleCtaClick={() => setShowNewTemplateModal(true)}
			/>
			<Card>
				<CardBody>
					<TaskTemplatesTable 
						taskTemplates={taskTemplates}
						reload={() => setTaskTemplates(null)}
					/>
				</CardBody>
			</Card>
			{ showNewTemplateModal && (
				<NewTaskTemplateModal 
					visible
					onClose={() => setShowNewTemplateModal(false)}
					reload={() => setTaskTemplates(null)}
				/>
			)}
		</>
	)
}