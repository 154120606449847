import React, { useState } from 'react'
import { Divider, Empty, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import NewReviewEquipmentModal from './partials/NewReviewEquipmentModal'
import EditReviewEquipmentModal from './partials/EditReviewEquipmentModal'

import { can, handleError, handleSuccess, text } from '../../helpers'
import { deleteReviewEquipment } from './services'

export default function ReviewEquipmentsTab({ serviceReview, reload }) {
	const [showNewModal, setShowNewModal] = useState(false)
	const [editModal, setEditModal] = useState(null)

	const handleDelete = id => {
		can('review_equipments.delete') && deleteReviewEquipment(id)
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Item',
			dataIndex: 'item'
		},
		{
			title: 'Equipo',
			dataIndex: 'name'
		},
		{
			title: 'Marca',
			dataIndex: 'brand'
		},
		{
			title: 'Código Interno',
			dataIndex: 'code',
			render: t => text(t)
		},
		{
			title: 'Serial',
			dataIndex: 'serial'
		},
		{
			title: 'Fecha de calibración / verificación',
			dataIndex: 'verified_at',
			render: t => moment(t).format('DD-MMM-YYYY').toUpperCase()
		},
		{
			title: 'Acciones',
			dataIndex: 'id',
			render: (t, r) => (
				<>
					<Tooltip title="Editar">
						<FontAwesomeIcon 
							icon={faEdit} 
							className='text-link' 
							onClick={() => setEditModal(r)}
						/>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Eliminar">
						<Popconfirm
							title="Esta acción es irreversible"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
						>
							<FontAwesomeIcon 
								icon={faTrash}
								className='text-link'
							/>
						</Popconfirm>
					</Tooltip>
				</>
			)
		}
	]

	return (
		<>
			<Table 
				dataSource={serviceReview.review_equipments}
				columns={columns}
				loading={!serviceReview.review_equipments}
				pagination={false}
				locale={{ emptyText: (
					<Empty description="No tiene equipos asociados aún" imageStyle={{ height: '70px' }} /> 
				)}}
				rowKey={r => r.id}
				size="small"
				className='mb-15'
			/>
			<div className='text-right'>
				<span className='text-link' onClick={() => setShowNewModal(true)}>
					Agregar Equipo de Revisión
				</span>
			</div>
			{ showNewModal && (
				<NewReviewEquipmentModal 
					visible
					onClose={() => setShowNewModal(false)}
					reload={reload}
					serviceReview={serviceReview}
				/>
			)}
			{ editModal && (
				<EditReviewEquipmentModal
					visible
					onClose={() => setEditModal(null)}
					reviewEquipment={editModal}
					reload={reload}
				/>
			)}
		</>
	)
}