import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'

import BranchForm from './BranchForm'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { storeBranch } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function NewBranchModal({ visible, onClose, reload, companyId }) {
	const { register, handleSubmit, formState: { errors }, watch } = useForm()
	const [selectedInstalationType, setSelectedInstalationType] = useState([])
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		values.company_id = companyId
		values.instalation_type_id = selectedInstalationType.value
		storeBranch(values)
			.then(res => handleSuccess(res.data.message, reload, onClose))
			.catch(error => handleError(error, setLoading))
	}

	if(!can('branches.create'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para crear instalaciones" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Agregar Instalación</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<BranchForm 
							register={register} 
							errors={errors}
							watch={watch}
							selectedInstalationType={selectedInstalationType}
							setSelectedInstalationType={setSelectedInstalationType}
						/>
						<Button color="primary" type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Agregar
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}