import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import AttachmentsList from './AttachmentsList'

export default function AttachmentsListModal({ 
    visible, 
    onClose, 
    scope,
    attachableType, 
    attachableId,
    title = 'Archivos Adjuntos',
}) {
    return (
        <>
            <Modal isOpen={visible} size="lg">
                <ModalHeader toggle={onClose}>{title}</ModalHeader>
                <ModalBody>
                    <AttachmentsList 
                        scope={scope}
                        attachableType={attachableType}
                        attachableId={attachableId}
                    />
                </ModalBody>
            </Modal>
        </>
    )
}