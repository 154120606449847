import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import BranchForm from './BranchForm'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { updateBranch } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function EditBranchModal({ visible, onClose, branch, reload }) {
	const { register, handleSubmit, formState: { errors }, reset, watch } = useForm()
	const [selectedInstalationType, setSelectedInstalationType] = useState([])

	const onSubmit = values => {
		if(selectedInstalationType.value) values.instalation_type_id = selectedInstalationType.value
		updateBranch(branch.id, values)
			.then(res => handleSuccess(res.data.message, reload, handleClose))
			.catch(error => handleError(error))
	}	

	const handleClose = () => {
		reset()
		onClose()
	}

	if(!can('branches.update'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar instalaciones" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible} toggle={handleClose}>
				<ModalHeader toggle={handleClose}>Actualizar Instalación</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<BranchForm
							branch={branch} 
							register={register}
							errors={errors}
							watch={watch}
							selectedInstalationType={selectedInstalationType}
							setSelectedInstalationType={setSelectedInstalationType}
						/>
						<Button type="submit" color="primary">Actualizar</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}