import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import moment from 'moment'

import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { getUserEquipments } from '../../UserEquipments/services'
import { storeReviewEquipment } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function NewReviewEquipmentModal({ visible, onClose, serviceReview, reload }) {
	const userId = serviceReview.service_plan.service_inspector.user_id;
	const [userEquipments, setUserEquipments] = useState(null)
	const [selectedEquipment, setSelectedEquipment] = useState(null)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		!userEquipments && can('review_equipments.create') && getUserEquipments({ 
			'filter[user_id]': userId 
		})
			.then(res => setUserEquipments(res.data.data))
			.catch(error => handleError(error))
	}, [ userEquipments, userId ])

	const onSubmit = () => {
		setLoading(true)
		storeReviewEquipment({
			service_review_id: serviceReview.id,
			item: selectedEquipment.item,
			name: selectedEquipment.name,
			brand: selectedEquipment.brand,
			code: selectedEquipment.code,
			serial: selectedEquipment.serial,
			verified_at: moment(selectedEquipment.verified_at).format('YYYY-MM-DD'),
		})
			.then(res => handleSuccess(res.data.message, reload, onClose))
			.catch(error => handleError(error, setLoading))
	}

	if(!can('review_equipments.create'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para crear equipos de medición" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Agregar Equipo de Medición</ModalHeader>
				<ModalBody>
					<Form.Group className='form-group'>
						<Form.Label>Equipo del Inspector <span className='text-danger'>*</span></Form.Label>
						{ userEquipments ? (
							<>
								{ userEquipments.length > 0 ? (
									<Form.Control
										as="select"
										value={selectedEquipment ? selectedEquipment.id : ''}
										onChange={e => setSelectedEquipment(userEquipments.find(equipment => equipment.id === parseInt(e.target.value)))}
									>
										<option>:: Selecione una opción ::</option>
										{ userEquipments.map( equipment => (
											<option key={equipment.id} value={equipment.id}>
												({equipment.item}) {equipment.name} {equipment.brand} [{equipment.serial}]
											</option>
										)) }
									</Form.Control>
								) : (
									<span className='text-warning'>
										El inspector del servicio no tiene equipos asignados por el administrador.
									</span>
								)}
							</>
						) : (
							<Spinner animation='border' size="sm" className='mr-5' />
						)}
					</Form.Group>
					<Button color='primary' onClick={onSubmit} disabled={!selectedEquipment || loading}>
						{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
						Agregar Equipo
					</Button>
				</ModalBody>
			</Modal>
		</>
	)
}