import React from 'react'
import { Form, InputGroup, Spinner } from 'react-bootstrap'
import { Button, Col, Row } from 'reactstrap'

import FormatVersionSelect from '../../../FormatVersions/FormatVersionSelect'
import { currency, dewar_vars, round } from '../../../../helpers'

export default function NewProposalForm({ form, setForm, errors, handleSubmit, businessScope, loading, defaultQuantity }) {

	const isInspection = businessScope === 'inspeccion'

	return (
		<>
			<InputGroup>
				<InputGroup.Text>CONSECUTIVO <span className='text-danger'>*</span></InputGroup.Text>
				<InputGroup.Text>{isInspection ? dewar_vars.proposal_ins_prefix : dewar_vars.proposal_tes_prefix}-</InputGroup.Text>
				<Form.Control
					value={form.sku_number ? form.sku_number : ''}
					onChange={e => setForm({ 
						...form, 
						sku_number: parseInt(e.target.value),
						sku_key: isInspection ? `${dewar_vars.proposal_ins_prefix}-${e.target.value}` : `${dewar_vars.proposal_tes_prefix}-${e.target.value}`
					})}
				/>
				{ errors.sku_key && <Form.Text className='text-warning'>Este campo es requerido y debe ser númerico</Form.Text> }
			</InputGroup>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Versión <span className='text-danger'>*</span></Form.Label>
					<FormatVersionSelect 
						keyword="proposal"
						onChange={(format_version_id, format_version_sku, expire_at, format_template) => {
							setForm({ 
								...form, 
								format_version: {
									sku: format_version_sku, 
									expire_at,
								},
								format_version_id, 
								format_template
							})
						}}
						placeholder='Escriba aquí...'
						businessScope={businessScope}
					/>
					{ errors.format_version_id && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Fecha Vigencia</Form.Label>
					<Form.Control 
						value={form.expire_at}
						disabled
					/>
					{ errors.expire_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Fecha Elaboración</Form.Label>
				<Form.Control 
					value={form.elaborated_at}
					onChange={e => setForm({ ...form, elaborated_at: e.target.value })}
					type="date"
				/>
				{ errors.elaborated_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Row>
				<Form.Group as={Col} sm="8">
					<Form.Label>Valor por Unidad <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						value={form.service_value}
						onChange={e => setForm({ ...form, service_value: e.target.value })}
					/>
					{ errors.service_value && <Form.Text className='text-warning'>Este campo es requerido y debe ser númerico</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Ctd <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						value={form.quantity}
						onChange={e => e.target.value > 0  && setForm({ ...form, quantity: e.target.value })}
					/>
					{ errors.quantity && <Form.Text className='text-warning'>Este campo es requerido y debe ser númerico</Form.Text> }
				</Form.Group>
			</Row>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Valor del Servicio</Form.Label>
					<Form.Control value={currency(round(parseInt(form.service_value)*parseInt(form.quantity),-1))} disabled />
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Total { isInspection ? 'Gastos de Viaje' : 'Gastos de Transporte Gravado' } <small>(opcional)</small></Form.Label>
					<Form.Control 
						value={form.trip_expenses_value}
						onChange={e => setForm({ ...form, trip_expenses_value: e.target.value })}
					/>
					{ errors.trip_expenses_value && <Form.Text className='text-warning'>Este campo es requerido y debe ser númerico</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Valor Total</Form.Label>
				<Form.Control value={currency((parseInt(form.service_value)*parseInt(form.quantity)) + parseInt(form.trip_expenses_value))} disabled />
			</Form.Group>
			<Form.Group>
				<Form.Label>Términos y condiciones del precio <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					value={form.price_terms}
					onChange={e => setForm({ ...form, price_terms: e.target.value })}
					placeholder='Escriba aquí...'
					as="textarea"
					style={{ height: '200px' }}
				/>
				{ errors.price_terms && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			{ isInspection && (
				<Form.Group>
					<Form.Label>¿Es Dominguez Sanchez SAS el constructor?</Form.Label>
					<div>
						<Form.Check 
							type="radio" 
							checked={form.is_ds_construction}
							label="Sí" 
							onChange={e => setForm({ ...form, is_ds_construction: 1 })} 
							inline
						/>
						<Form.Check 
							type="radio" 
							checked={!form.is_ds_construction}
							label="No" 
							onChange={e => setForm({ ...form, is_ds_construction: 0 })}
							inline
						/>
					</div>
				</Form.Group>
			)}
			<Button color="primary" onClick={handleSubmit} className='mt-15' disabled={loading}>
				{loading && <Spinner animation='border' size="sm" />}{" "}
				Enviar Propuesta
			</Button>
		</>
	)
}