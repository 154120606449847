import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap'
import { message } from 'antd'

import PermissionIsRequired from '../../../../components/PermissionIsRequired'

import { offlineUpdateReview } from '../../../Services/actions'
import { queueRequest } from '../../../Offline/actions'

import { updateServiceReview } from '../../services'
import { can, handleError, handleSuccess } from '../../../../helpers'

export default function ReviewResultsModal({ visible, onClose, serviceReview, serviceId, businessScope, reload }) {
	const { isConnected } = useSelector(state => state.offline)
	const { register, handleSubmit } = useForm({
		defaultValues: {
			review_specs: {
				results_if_compliant: serviceReview.results_if_compliant ? serviceReview.results_if_compliant : ((businessScope === 'pruebas') ? 'Se verifico que en los tanques que pasaron la prueba: \n- Se alcanzo la presión. \n- Se mantuvo la presión. \nSe verificó que en las tuberías que pasaron la prueba: \n- Se alcanzo la presión. \n- Se mantuvo la presión. \n- La decisión de conformidad será declarada por el Director Técnico una vez se revisen todos los registros del proceso.' : ''),
				results_if_not_compliant: serviceReview.results_if_not_compliant ? serviceReview.results_if_not_compliant : ((businessScope === 'pruebas') ? 'Si alguno de los items presenta resultado con no cumplimiento, El Cliente tendrá que realizar los correctivos necesarios y solicitar un proceso de inspección para evaluar la conformidad del tanque o tubería. La decisión de no conformidad será declarada por el Director Técnico. El tanque o la tubería con no cumplimiento no se incluirá dentro de la Declaración de la conformidad.' : ''),
				final_declaration: serviceReview.final_declaration ? serviceReview.final_declaration : ((businessScope === 'pruebas') ? 'Una vez finalizado el servicio se da constancia que la instalación se entrega y es recibida a satisfacción por el Cliente, en buenas condiciones y  con los equipos en funcionamiento.' : ''),
			}
		}
	})
	const dispatch = useDispatch()

	const onSubmit = values => {
		if(isConnected){
			updateServiceReview(serviceReview.id, {
				...values,
				review_specs: {
					...serviceReview.review_specs,
					...values.review_specs
				}
			})
				.then(res => handleSuccess(res.data.message, reload, onClose))
				.catch(error => handleError(error))
		} else {
			dispatch(offlineUpdateReview(values, serviceId))
			dispatch(queueRequest({url: `/service_reviews/${serviceReview.id}`, data: values}))
			message.info('Actualización en espera de internet')
			onClose()
		}
	}

	if(!can('service_reviews.update'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar actas de inspección" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Resultados de Inspección</ModalHeader>
				<ModalBody>
					{ serviceReview ? (
						<Form onSubmit={handleSubmit(onSubmit)}>
							{ businessScope === 'inspeccion' && (
								<Row>
									<Form.Group as={Col}>
										<Form.Label>Fecha de Inicio:</Form.Label>
										<Form.Control 
											type="date"
											{...register('review_specs.start_at', { required: true })}
										/>
									</Form.Group>
									<Form.Group as={Col}>
										<Form.Label>Fecha Finalización:</Form.Label>
										<Form.Control 
											type="date"
											{...register('review_specs.end_at', { required: true })}
										/>
									</Form.Group>
								</Row>
							)}
							<Form.Group>
								<Form.Label>Resultados con Cumplimiento <span className='text-danger'>*</span></Form.Label>
								<Form.Control 
									as="textarea"
									style={{ height:'100px' }}
									{...register('review_specs.results_if_compliant', { required: true })}
									defaultValue={serviceReview.review_specs.results_if_compliant}
									placeholder='Escriba aquí...'
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Resultados sin Cumplimiento <span className='text-danger'>*</span></Form.Label>
								<Form.Control 
									as="textarea"
									style={{ height:'100px' }}
									{...register('review_specs.results_if_not_compliant', { required: true })}
									defaultValue={serviceReview.review_specs.results_if_not_compliant}
									placeholder='Escriba aquí...'
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Declaración Finalización del Servicio <span className='text-danger'>*</span></Form.Label>
								<Form.Control 
									as="textarea"
									style={{ height:'100px' }}
									{...register('review_specs.final_declaration', { required: true })}
									defaultValue={serviceReview.review_specs.final_declaration}
									placeholder='Escriba aquí...'
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Observaciones del Inspector <em>(Opcional)</em></Form.Label>
								<Form.Control 
									as="textarea"
									style={{ height:'100px' }}
									{...register('observations', {})}
									defaultValue={serviceReview.observations}
									placeholder='Escriba aquí...'
								/>
							</Form.Group>
							<Button color="primary" type="submit">Actualizar Información</Button>
						</Form>
					) : <Spinner />}
				</ModalBody>
			</Modal>
		</>
	)
}