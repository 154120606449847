import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Upload } from "antd"
import { InboxOutlined } from '@ant-design/icons'
import AWS from "aws-sdk"

import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { storeEvidence } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

const { Dragger } = Upload

export default function NewEvidenceModal({ 
	visible, onClose, evidenceTemplate, verificationTask, verificationListId, reload 
}) {
	const props = {
		multiple: false,
		customRequest({
			action,
			data,
			file,
			filename,
			headers,
			onError,
			onProgress,
			onSuccess,
			withCredentials
		}) {
			AWS.config.update({
				accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    			secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
			});
		
			const S3 = new AWS.S3({
				params: { Bucket: 'dewar' },
				region: 'us-east-1',
		  });

		  let fileExt = file.name.split('.').pop();
		  let uri = `app/evidence/${verificationListId}/${Date.now()}.${fileExt}`
		
			const objParams = {
				Bucket: "dewar",
				Key: uri,
				Body: file,
				ContentType: file.type
			};
		
			S3.putObject(objParams)
				.on("httpUploadProgress", function({ loaded, total }) {
					onProgress(
						{ percent: Math.round((loaded / total) * 100) },
						file
					);
				})
				.send(function(err, data) {
					if (err) {
						onError();
						console.error('Error al subir imagen: ', err.message)
					} else {
						onSuccess(data.response, file);						
						storeEvidence({
							verification_list_id: verificationListId,
							evidence_template_id: evidenceTemplate.id,
							attachment_uri: `https://dewar.s3.us-east-1.amazonaws.com/${uri}`
						})
							.then(res => handleSuccess(res.data.message, reload, onClose))
							.catch(error => handleError(error))
					}
				});
			}
		}
	
		if(!can('evidence.create'))
			return <PermissionIsRequired type="modal" title="No tiene permisos para crear evidencia" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Adjuntar {evidenceTemplate.type_name}</ModalHeader>
				<ModalBody>
					<Dragger {...props}>
						<p className="ant-upload-drag-icon">
							<InboxOutlined />
						</p>
						<p className="ant-upload-text">Haga clic o arraste {evidenceTemplate.name}</p>
						<p className="ant-upload-hint">
							Solo se permite adjuntar un archivo menor a 5MB
						</p>
					</Dragger>
				</ModalBody>
			</Modal>
		</>
	)
}