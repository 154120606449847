import React, { useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'

import ServerSideSelect from '../../../../components/ServerSideSelect'
import PermissionIsRequired from '../../../../components/PermissionIsRequired'

import { searchInspectors } from '../../../Users/services'
import { updateServiceReport } from '../../services'
import { can, handleError, handleSuccess } from '../../../../helpers'

export default function AssignReportInspectorModal({ visible, onClose, serviceReport, reload }) {
	const [inspectorSelected, setInspectorSelected] = useState([])

	const onSubmit = () => {
		updateServiceReport(serviceReport.id, {
			inspector_user_id: inspectorSelected.value
		})
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

	if(!can('service_reports.update'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar informes de inspección" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Asignar Inspector del Informe</ModalHeader>
				<ModalBody>
					<Form>
						<Form.Group>
							<Form.Label>Nombre Inspector (a) miembro Comité Técnico: <span className='text-danger'>*</span></Form.Label>
							<ServerSideSelect 
								value={inspectorSelected}
								placeholder="Buscar por nombre de inspector"
								fetchOptions={searchInspectors}
								onChange={value => setInspectorSelected(value)}
								className="full-width mb-10"
							/>
							{ !inspectorSelected.value && <Form.Text className='text-warning'>Este campo es requerido</Form.Text>}
						</Form.Group>
						<Button color="primary" onClick={onSubmit} disabled={!inspectorSelected.value}>
							Asignar Inspector
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}