import React, { useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import ServiceForm from '../ServiceForm'
import PermissionIsRequired from '../../../../components/PermissionIsRequired'

import { storeService } from '../../services'
import { can, handleError, handleSuccess } from '../../../../helpers'

export default function NewServiceModal({ visible, onClose, businessScope }) {
	const { register, handleSubmit, formState: { errors } } = useForm()
	const [selectedProposal, setSelectedProposal] = useState([])
	const [loading, setLoading] = useState(false)
	const history = useHistory()

	const onSubmit = values => {
		setLoading(true)
		storeService({
			...values, 
			proposal_id: selectedProposal.value
		})
			.then(res => handleSuccess(res.data.message, () => history.push(`/services/${res.data.data.id}`)))
			.catch(error => handleError(error, setLoading))
	}

	if(!can('services.create'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para crear servicios" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Nueva Inspección</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<ServiceForm 
							register={register} 
							errors={errors} 
							selectedProposal={selectedProposal}
							setSelectedProposal={setSelectedProposal}
							businessScope={businessScope}
						/>
						<Button color="primary" type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Iniciar Inspección
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}