import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import moment from 'moment'

import UserEquipmentForm from './UserEquipmentForm'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { updateUserEquipment } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function EditUserEquipmentModal({ visible, onClose, userEquipment, reload }) {
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: {
			...userEquipment,
			verified_at: moment(userEquipment.verified_at).format('YYYY-MM-DD'),
			last_delivered_at: moment(userEquipment.last_delivered_at).format('YYYY-MM-DD'),
		}
	})

	const onSubmit = values => {
		updateUserEquipment(userEquipment.id, values)
			.then(res => handleSuccess(res.data.message, reload, onClose))
			.catch(error => handleError(error))
	}
	
	if(!can('user_equipments.update'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar los equipos de medición" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Actualizar Equipo de Medición</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<UserEquipmentForm register={register} errors={errors} />
						<Button color='primary' type='submit'>Actualizar Equipo</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}