import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button, Spinner } from 'reactstrap'

import ReportEvidenceGrid from './partials/ReportEvidenceGrid'
import NewReportEvidenceModal from './partials/NewReportEvidenceModal'
import EvidenceImagesSliderModal from '../Evidence/partials/EvidenceImagesSliderModal'
import InternetRequired from '../Offline/partials/InternetRequired'

import { getReportEvidence } from './services'
import { can, handleError } from '../../helpers'

export default function ReportEvidenceList({ serviceReportId, serviceReviewId, editable, directory }) {
	const { isConnected } = useSelector(state => state.offline)
	const [reportEvidence, setReportEvidence] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)
	const [showSliderModal, setShowSliderModal] = useState(false)

	useEffect(() => {
		!reportEvidence && isConnected && can('report_evidence.viewAll') && getReportEvidence({
			'filter[service_report_id]': serviceReportId ? serviceReportId : '',
			'filter[service_review_id]': serviceReviewId ? serviceReviewId : '',
			'filter[attachment_uri]': directory
		})	
			.then(res => setReportEvidence(res.data.data))
			.catch(error => handleError(error))
	}, [ serviceReportId, serviceReviewId, reportEvidence, isConnected, directory ])

	if(!isConnected) return <InternetRequired />

	if(!reportEvidence) return <Spinner size="sm" />
	
	return (
		<>
			<ReportEvidenceGrid 
				reportEvidence={reportEvidence}
				reload={() => setReportEvidence(null)}
				editable={editable}
			/>
			{ editable && (
				<div className='text-right'>
					<p className='text-link' onClick={() => setShowNewModal(true)}>
						Agregar Registro Fotográfico
					</p>
				</div>
			)}
			<Button color='primary' outline className='mr-10' onClick={() => setShowSliderModal(true)}>
				Ver carrete de fotos
			</Button>
			{ showNewModal && (
				<NewReportEvidenceModal 
					visible
					onClose={() => setShowNewModal(false)}
					serviceReportId={serviceReportId}
					serviceReviewId={serviceReviewId}
					reload={() => setReportEvidence(null)}
					directory={directory}
				/>
			)}
			{ showSliderModal && (
				<EvidenceImagesSliderModal
					visible
					onClose={() => setShowSliderModal(false)}
					evidence={reportEvidence}
				/>
			)}
		</>
	)
}