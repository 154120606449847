import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Badge } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { Divider, Table, Tooltip } from 'antd'
import moment from 'moment'

import PublishCertificateModal from './modals/PublishCertificateModal'
import Pagination from '../../../components/Pagination'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { can } from '../../../helpers'

export default function CertificatesTable({ certificates, reload, pagination, setPagination }) {
	const [publishCertificateModal, setPublishCertificateModal] = useState(null)

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			render: t => <Link to={`/certificates/preview/${t}`}>#{t}</Link>
		},
		{
			title: 'SKU',
			dataIndex: 'sku'
		},
		{
			title: 'Fecha de Emisión',
			dataIndex: 'issued_at',
			render: t => moment(t).format('DD-MMM-YYYY').toUpperCase()
		},
		{
			title: 'Empresa',
			dataIndex: 'service_veredict',
			render: t => t.service_report.service_review.service_plan.service_inspector.service.proposal.inquiry.branch_spec.branch.company.name
		},
		{
			title: 'Instalación',
			dataIndex: 'service_veredict',
			render: t => t.service_report.service_review.service_plan.service_inspector.service.proposal.inquiry.branch_spec.branch.name
		},
		{
			title: 'Tipo de Instalación',
			dataIndex: 'service_veredict',
			render: t => t.service_report.service_review.service_plan.service_inspector.service.proposal.inquiry.branch_spec.branch.instalation_type_name
		},
		{
			title: 'Propuesta',
			dataIndex: 'service_veredict',
			render: t => t.service_report.service_review.service_plan.service_inspector.service.proposal.sku
		},
		{
			title: 'Portal Clientes',
			dataIndex: 'published_at',
			render: (t, r) => (
				<Tooltip title={t ? t : 'Haga clic para publicar'}>
					<Badge
						color={t ? 'success' : 'dark'}
						style={{ cursor: 'pointer' }}
						onClick={() => t ? {} : setPublishCertificateModal(r)}
					>
						{ t ? 'Publicado' : 'Sin Publicar' }
					</Badge>
				</Tooltip>
			)
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<>
					<Tooltip title="Ver Certificado">
						<Link to={`/certificates/preview/${r.id}`}>
							<FontAwesomeIcon className="text-link" icon={faSearch} />
						</Link>
					</Tooltip>
					{ r.attachment_url && (
						<>
							<Divider type="vertical" />
							<a href={r.attachment_url} target="_blank" rel="noreferrer">Ver Adjunto</a>
						</>
					)}
				</>
			)
		}
	]

	if(!can('certificates.viewAll'))
		return <PermissionIsRequired title="No tiene acceso a ver listado de certificados" />
	
	return (
		<>
			<Table 
				dataSource={certificates}
				loading={!certificates}
				columns={columns}
				rowKey={r => r.id}
				size="small"
				pagination={false}
				className="mb-15"
			/>
			<Pagination
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			{ publishCertificateModal && (
				<PublishCertificateModal 
					visible
					onClose={() => setPublishCertificateModal(null)}
					certificate={publishCertificateModal}
					reload={reload}
				/>
			)}
		</>
	)
}