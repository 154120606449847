import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardSubtitle } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import NewUserForm from './partials/NewUserForm'
import UsersTable from './partials/UsersTable'
import InternetRequired from '../Offline/partials/InternetRequired'

import { getUsers } from './services'
import { can, handleError } from '../../helpers'

export default function UsersList() {
	const { isConnected } = useSelector(state => state.offline)
	const [users, setUsers] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')

	useEffect(()=>{
		!users && isConnected && can('users.viewAll') && getUsers({
			include: 'roles',
			[`filter[${filterType}]`]: filterValue
		})
			.then(res => setUsers(res.data.data))
			.catch(error => handleError(error))
	}, [users, filterType, filterValue, isConnected])

	if(!isConnected) return <InternetRequired />

	return (
		<>
			<Row>
				<Col md="3">
					<Card>
						<CardHeader>
							<CardTitle>Agregar Usuario Nuevo</CardTitle>
							<CardSubtitle>Ingrese los datos de nuevo usuario</CardSubtitle>
						</CardHeader>
						<CardBody>
							<NewUserForm reload={()=>setUsers(null)} />
						</CardBody>
					</Card>
				</Col>
				<Col md="9">
					<ListTopBar 
						filterType={filterType}
						setFilterType={setFilterType}
						filterValue={filterValue}
						setFilterValue={setFilterValue}
						title="Directorio de usuarios"
						subtitle="Usuarios de plataforma Dewar y App Inspector"
						reload={() => setUsers(null)}
						options={[
							{ label: 'Buscar por nombre', value: 'name' },
							{ label: 'Buscar por cedula', value: 'document' },
						]}
					/>
					<Card>
						<CardBody>
							<UsersTable users={users} reload={()=>setUsers(null)} />
						</CardBody>
					</Card>
				</Col>
			</Row>			
		</>
	)
}