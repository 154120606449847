import React, { useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'

import EvidenceTemplateForm from './EvidenceTemplateForm'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { storeEvidenceTemplate } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function NewEvidenceTemplateModal({ visible, onClose, reload }) {
	const { register, handleSubmit, formState: { errors } } = useForm()
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		storeEvidenceTemplate(values)
			.then(res => handleSuccess(res.data.message, reload, onClose))
			.catch(error => handleError(error, setLoading))
	}

	if(!can('evidence_templates.create'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para crear plantillas de evidencia" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Crear Plantilla de Evidencia</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<EvidenceTemplateForm register={register} errors={errors} />
						<Button color="primary" type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Agregar Plantilla
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}