import React from 'react'
import { Form } from 'react-bootstrap'

import UploadPaymentReceiptAttachment from './UploadPaymentReceiptAttachment'

export default function PaymentReceiptForm({ register, setAttachmentUri, proposalId }) {
	return (
		<>
			<Form.Group>
				<Form.Label>Subir Soporte de Pago</Form.Label>
				<UploadPaymentReceiptAttachment 
					setAttachmentUri={setAttachmentUri}
					proposalId={proposalId}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Comentarios <small>(opcional)</small></Form.Label>
				<Form.Control 
					as="textarea"
					{...register('comments', { })}
					placeholder="Escriba aquí..."
				/>
			</Form.Group>
		</>
	)
}