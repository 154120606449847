import React, { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import BranchesTable from './partials/BranchesTable'
import BranchEmployeeAttachModal from './partials/BranchEmployeeAttachModal'

import { getBranches } from './services'
import { can, handleError, parsePagination } from '../../helpers'

export default function EmployeeBranchesModal({ visible, onClose, employee }) {
	const [branches, setBranches] = useState(null)
	const [attachToEmployee, setAttachToEmployee] = useState(false)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!branches && can('branches.viewAll') && getBranches({ 
			...pagination,
			'filter[employees.id]': employee.id,
			include: 'instalationType,employees'
		})
			.then(res => {
				setBranches(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [branches, employee, pagination])

	return (
		<>
			<Modal isOpen={visible} size="xl">
				<ModalHeader toggle={onClose}>Instalaciones Asociadas a {employee.name}</ModalHeader>
				<ModalBody>
					<BranchesTable
						branches={branches}
						reload={() => setBranches(null)}
						pagination={pagination}
						setPagination={setPagination}
						employee={employee}
					/>
					<Button color='primary' onClick={() => setAttachToEmployee(true)}>
						Asociar Sede Existente
					</Button>
				</ModalBody>
			</Modal>
			{ attachToEmployee && (
				<BranchEmployeeAttachModal 
					visible
					onClose={() => setAttachToEmployee(false)}
					reload={() => setBranches(null)}
					employee={employee}
				/>
			)}
		</>
	)
}