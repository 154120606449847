import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Alert, Spinner } from 'reactstrap'
import styled from 'styled-components'
import { Divider } from 'antd'

import SendNotificationButton from '../../components/SendNotificationButton'
import ProposalDocument from './partials/ProposalDocument'
import AcceptanceDocument from './partials/AcceptanceDocument'
import InternetRequired from '../Offline/partials/InternetRequired'

import { notifyProposal, publicGetProposal } from './services'
import { handleError } from '../../helpers'

export default function ProposalPreview(props) {
	const { token } = useSelector(state => state.auth)
	const { isConnected } = useSelector(state => state.offline)
	const id = props.id ? props.id : props.match.params.id
	const [proposal, setProposal] = useState(null)
	
	useEffect(() => {
		!proposal && isConnected && publicGetProposal(id, {
			include: 'inquiry.branchSpec.branch.instalationType,inquiry.employee,inquiry.branchSpec.branch.company'
		})
			.then(res => setProposal(res.data.data))
			.catch(error => handleError(error))
	}, [proposal, id, isConnected])

	if(!isConnected) return <InternetRequired />

	if(!proposal) return <Spinner />

	const data = {
		...proposal,
		employee_name: proposal.inquiry.employee.name,
		employee_position: proposal.inquiry.employee.position,
		branch_name: proposal.inquiry.branch_spec.branch.name,
		branch_address: proposal.inquiry.branch_spec.branch.address_line,
		branch_state: proposal.inquiry.branch_spec.branch.address_state,
		branch_municipio: proposal.inquiry.branch_spec.branch.address_city,
		branch_type_name: proposal.inquiry.branch_spec.branch.instalation_type.name,
		branch_type_criteria: proposal.inquiry.branch_spec.branch.instalation_type.criteria,
		total: (parseInt(proposal.service_value)*parseInt(proposal.quantity)) + parseInt(proposal.trip_expenses_value),
		inquiry: proposal.inquiry,
		format_template: proposal.format_version.format_template,
	}

	return (
		<>
			{ !proposal.published_at && (
				<Alert color="warning">Propuesta en estado borrador. Sí desea enviarla debe publicarla primero.</Alert>
			)}
			<Container>
				{ !proposal.previous_certificate_id ? (
					<>
						<ProposalDocument data={data} />
						{ token && (
							<div className="no-print">
								<Divider />
								<SendNotificationButton notifyService={() => notifyProposal(proposal.id)} />
							</div>
						)}
					</>
				) : (
					<>
						<ProposalDocument data={data} isRecertification />
						{ token && (
							<div className="no-print">
								<Divider />
								<SendNotificationButton notifyService={() => notifyProposal(proposal.id)} />
							</div>
						)}
					</>
				)}
			</Container>
			<AcceptanceDocument proposal={proposal} hideHeader />
		</>
	)
}

const Container = styled.div`
	padding: 0px 10px;
`