import React, { useState } from 'react'
import { Alert, Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message, Select } from 'antd'

import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { storeEvidence } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

const { Option } = Select

export default function CloneFilesFromItemModal({ 
	visible, onClose, reloadEvidence, evidenceTemplate, evidence, evidenceTemplates 
}) {
	const [selectedTemplateId, setSelectedTemplateId] = useState(null)

	const onSubmit = () => {
		const sources = evidence.filter(e => selectedTemplateId === e.evidence_template_id)

		if(sources.length === 0) 
			return message.info('El item seleccionado no tiene evidencias subidas')

		for (var i = 0; i < sources.length; i++) {
			if(i === (sources.length - 1)){
				storeEvidence({ ...sources[i], evidence_template_id: evidenceTemplate.id })
					.then(res => handleSuccess(res.data.message, reloadEvidence, onClose))
					.catch(error => handleError(error))
			} else {
				storeEvidence({ ...sources[i], evidence_template_id: evidenceTemplate.id })
					.catch(error => handleError(error))
			}
		}
	}

	if(!can('evidence.create'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para crear evidencia" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Clonar eviencia de otro item</ModalHeader>
				<ModalBody>
					<Alert color='info'>Al usar esta funcionalidad va a copiar todas las evidencias del item que seleccione al tiem <strong>{evidenceTemplate.item}</strong></Alert>
					<Select
						showSearch
						placeholder="Seleccione un item"
						optionFilterProp="children"
						onChange={value => setSelectedTemplateId(value)}
						value={selectedTemplateId}
						filterOption={(input, option) => option ? option.toLowerCase().includes(input.toLowerCase()) : false}
						style={{ width: '100%' }}
					>
						{ evidenceTemplates.map( template => (
							<Option key={template.id} value={template.id}>
								{template.item} {template.name.substring(0,50)}
							</Option>
						)) }
					</Select>
					<Button color="primary" className='mt-15' onClick={onSubmit}>
						Clonar Evidencia
					</Button>
				</ModalBody>
			</Modal>
		</>
	)
}