import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message, Descriptions } from 'antd'

import VerificationTaskForm from '../../VerificationTasks/partials/VerificationTaskForm'
import TaskEvidenceModal from '../../Evidence/TaskEvidenceModal'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { offlineUpdateTasks } from '../../Services/actions'
import { queueRequest } from '../../Offline/actions'

import { storeVerificationTask } from '../../VerificationTasks/services'
import { can, handleError } from '../../../helpers'

const { Item } = Descriptions

export default function RegisterPendingTaskModal({ visible, onClose, verificationList, taskTemplate, serviceReviewId, reload, serviceId }) {
	const { isConnected } = useSelector(state => state.offline)
	const { register, handleSubmit, formState: { errors }, setValue } = useForm()
	const [showEvidenceModal, setShowEvidenceModal] = useState(null)
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()

	const onSubmit = values => {
		setLoading(true)
		const data = {
			service_review_id: serviceReviewId,
			verification_list_id: verificationList.id,
			task_template_id: taskTemplate.id,
			is_active: values.status !== 'NA' ? 1 : 0,
			is_compliant: values.status === 'C' ? 1 : 0,
			is_require: (values.status === 'C' || values.status === 'NC') ? 1 : 0,
			observations: values.observations,
			initially_required: (values.status === 'C' || values.status === 'NC') ? 1 : 0
		}
		if(isConnected){
			storeVerificationTask(data)
				.then(res => {
					message.success(res.data.message)
					setShowEvidenceModal(res.data.data)
					reload()
				})
				.catch(error => handleError(error))
				.finally(() => setLoading(false))
		} else {
			dispatch(offlineUpdateTasks({...data, task_template: taskTemplate}, serviceId))
			dispatch(queueRequest({ method:'POST', url:'/verification_tasks', data }))
			message.info('Solicitud a la espera de internet')
			reload()
			onClose()
		}
	}

	if(!can('verification_list.update'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar listas de verificación" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Tarea de Verificación</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<VerificationTaskForm 
							register={register}
							errors={errors}
							setValue={setValue}
							taskTemplate={taskTemplate}
						/>
						<Button color="primary" type="submit" disabled={loading}>Registrar Tarea</Button>
					</Form>
					<Descriptions bordered size="small" column={1} className='mt-20'>
						<Item label="Artículo">{taskTemplate.article}</Item>
						<Item label="Plazo">{taskTemplate.description}</Item>
						<Item label="Descripción General (Resolución)">{taskTemplate.legal_description}</Item>
						<Item label="Aplicación para Instalaciones">{taskTemplate.instalation_period}</Item>
						<Item label="Característica Mminas">{taskTemplate.minminas_criteria}</Item>
						<Item label="Evidencia Mminas">{taskTemplate.general_criteria}</Item>
					</Descriptions>
				</ModalBody>
			</Modal>
			{ showEvidenceModal && (
				<TaskEvidenceModal 
					visible
					onClose={() => setShowEvidenceModal(null)}
					verificationTask={showEvidenceModal}
					verificationListId={verificationList.id}
					editable
					reload={() => onClose()}
				/>
			)}
		</>
	)
}