import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button } from 'reactstrap'

import PermissionIsRequired from '../../../components/PermissionIsRequired'
import RoleForm from './RoleForm'

import { storeRole } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function NewRoleForm({ reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm({
		defaultValues: {
			guard_name: 'web'
		}
	})
		
	const onSubmit = values => {
		storeRole(values)
		.then(res => handleSuccess(res.data.message, reload, reset))
		.catch(error => handleError(error))
	}

	if(!can('roles.create'))
		return <PermissionIsRequired type="warning" title="No tiene permisos para crear roles" />
		
	return (
		<>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<RoleForm 
					register={register} 
					errors={errors}
				/>
				<Button type="submit" color="primary">Crear Usuario</Button>
			</Form>
		</>
	)
}