import React from 'react'
import { useHistory } from 'react-router'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { message } from 'antd'

import { BottomBar, SectionTitle, SectionContent } from '../../../../components/WizardComponents'

import { storeInquiry } from '../../services'
import { handleError, handleSuccess } from '../../../../helpers'

export default function TestDetailsStep({ form, setForm, currentStep, setCurrentStep }) {
	const history = useHistory()

	const handleBackStep = () => {
		setCurrentStep(currentStep-4)
	}

	const handleNextStep = () => {
		if(!form.invoice_requirements) return message.warning('Debe ingresar lo requisitos para facturación')
		if(!form.observations) return message.warning('Debe todos los campos. En caso de no aplicar coloque N/A.')
		if(!form.sent_by) return message.warning('Debe ingresar el nombre de solicitante')
		if(!form.sent_at) return message.warning('Debe ingresar la fecha de envio')
		if(!form.test_details.total_tanks) return message.warning('Debe ingresar el número de tanques')
		if(!form.test_details.service_name) return message.warning('Debe ingresar el nombre del servicio')

		storeInquiry(form)
			.then(res => handleSuccess(res.data.message, () => history.push('/inquiries')))
			.catch(error => handleError(error))
	}

	return (
		<>
			<SectionTitle>REQUISITOS DEL CLIENTE</SectionTitle>
			<SectionContent>
				<p>Indique si su empresa requiere de un trámite específico para realizar la radicación o cancelación de las facturas: <span className='text-danger'>*</span></p>
				<Form.Control 
					as="textarea"
					value={form.invoice_requirements}
					onChange={e => setForm({ ...form, invoice_requirements: e.target.value })}
					placeholder='Escriba aquí...'
					style={{ height: '80px' }}
				/>	
			</SectionContent>
			<SectionTitle>INFORMACIÓN ADICIONAL ESPECÍFICA ÚTIL PARA LA PRESTACIÓN DEL SERVICIO: <span className='text-danger'>*</span></SectionTitle>
			<SectionContent>
				<Form.Control 
					as="textarea"
					value={form.observations}
					onChange={e => setForm({ ...form, observations: e.target.value })}
					placeholder='Escriba aquí...'
					style={{ height: '80px' }}
				/>
				<Row>
					<InputGroup as={Col}>
						<InputGroup.Text>Número de tanques: <span className='text-danger'>*</span></InputGroup.Text>
						<Form.Control 
							value={form.test_details.total_tanks}
							onChange={e => setForm({ ...form, test_details: { ...form.test_details, total_tanks: e.target.value } })}
							placeholder='Escriba aquí...'
							type='number'
						/>
					</InputGroup>
					<InputGroup as={Col}>
						<InputGroup.Text>Altura aproximada del desfogue:</InputGroup.Text>
						<Form.Control 
							value={form.test_details.aprox_altitude}
							onChange={e => setForm({ ...form, test_details: { ...form.test_details, aprox_altitude: e.target.value } })}
							placeholder='Escriba aquí...'
						/>
					</InputGroup>
					<InputGroup as={Col}>
						<InputGroup.Text>¿Tiene válvula para independizar el desfogue?:</InputGroup.Text>
						<Form.Control 
							value={form.test_details.valve_present}
							onChange={e => setForm({ ...form, test_details: { ...form.test_details, valve_present: e.target.value } })}
							as="select"
						>
							<option values="">:: Seleccione una opción ::</option>
							<option>No</option>
							<option>Sí</option>
						</Form.Control>
					</InputGroup>
				</Row>
				<Row>
					<InputGroup as={Col}>
						<InputGroup.Text>Servicio que solicita: <span className='text-danger'>*</span></InputGroup.Text>
						<Form.Control 
							value={form.test_details.service_name}
							onChange={e => setForm({ ...form, test_details: { ...form.test_details, service_name: e.target.value } })}
							as="select"
						>
							<option value="">:: Seleccione una opción ::</option>
							<option>Pruebas de Hermeticidad de Tanques y Tuberías</option>
							<option>Pruebas de Hermeticidad de Tanques</option>
							<option>Pruebas de Hermeticidad de Tuberías</option>
						</Form.Control>
					</InputGroup>
					<InputGroup as={Col}>
						<InputGroup.Text>Fecha últimas pruebas de hermeticidad:</InputGroup.Text>
						<Form.Control 
							value={form.test_details.last_test_at}
							onChange={e => setForm({ ...form, test_details: { ...form.test_details, last_test_at: e.target.value } })}
							type="date"
						/>
					</InputGroup>
				</Row>
				<Row>
					<InputGroup as={Col}>
						<InputGroup.Text>Nombre de quien envia la solicitud: <span className='text-danger'>*</span></InputGroup.Text>
						<Form.Control 
							value={form.sent_by}
							onChange={e => setForm({ ...form, sent_by: e.target.value })}
							placeholder='Escriba aquí...'
						/>
					</InputGroup>
					<InputGroup as={Col}>
						<InputGroup.Text>Fecha de envío de la solicitud: <span className='text-danger'>*</span></InputGroup.Text>
						<Form.Control 
							value={form.sent_at}
							onChange={e => setForm({ ...form, sent_at: e.target.value })}
							type="date"
						/>
					</InputGroup>
				</Row>
			</SectionContent>
			<BottomBar 
				handleNextStep={handleNextStep} 
				handleBackStep={handleBackStep} 
				nextBtnText="Guardar y Finalizar"
			/>
		</>
	)
}