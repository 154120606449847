import React from 'react'
import { Button } from 'reactstrap'
import { Popconfirm, Table } from 'antd'

import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { deleteReportDefaultEvidence } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function ReportDefaultEvidenceTable({ reportDefaultEvidences, reload }) {

	const handleDelete = id => {
		can('report_default_evidence.delete') && deleteReportDefaultEvidence(id)
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

    const columns = [
        { 
            title: 'Evidencia', 
            dataIndex: 'evidence_template', 
            render: t => `[${t.item}] ${t.name}` 
        },
        { 
            title: 'Acciones', 
            render: (t,r) => (
                <Popconfirm
                    title="Esta acción no se puede revertir"
                    okText="Eliminar"
                    cancelText="Cancelar"
                    okButtonProps={{ danger: true }}
                    onConfirm={() => handleDelete(r.id)}
                >
                    <Button color="danger" size="sm" outline>Eliminar</Button>
                </Popconfirm>
            )
        }
    ]

    if(!can('report_default_evidence.viewAll'))
        return <PermissionIsRequired title='No tiene permisos para ver esta informaciónd' />

    return (
        <>
            <Table
                dataSource={reportDefaultEvidences}
                rowKey={r => r.id}
                columns={columns}
                loading={!reportDefaultEvidences}
                size="small"
            />
        </>
    )
}