import React from 'react'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { deleteAttachment } from '../services'
import { can, handleError } from '../../../helpers'

export default function AttachmentsTable({ attachments, reload }) {

    const handleDelete = id => {
        deleteAttachment(id)
            .then(res => {
                message.success(res.data.message)
                reload()
            })
            .catch(error => handleError(error))
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: 'Etiqueta del Archivo',
            dataIndex: 'label',
            render: t => t ? t : <em>Sin especificar</em>
        },
        {
            title: 'Fecha de Subida',
            dataIndex: 'created_at',
            render: t => moment(t).format('YYYY-MM-DD hh:mm A')
        },
        {
            title: 'Acciones',
            dataIndex: 'url',
            render: (t, r) => (
                <>
                    <Tooltip title="Descargar Adjunto">
                        <a target='_blank' href={t} rel="noopener noreferrer" className='text-link'>
                            <FontAwesomeIcon icon={faDownload} />
                        </a>
                    </Tooltip>
                    <Divider type="vertical" />
                    <Tooltip title="Eliminar">
                        <Popconfirm
                            title="¿Está seguro de esta acción?"
                            okText="Eliminar"
                            cancelText="Cancel"
                            okButtonProps={{ danger:true }}
                            onConfirm={() => handleDelete(r.id)}
                        >
                            <FontAwesomeIcon icon={faTrash} className='text-link' />
                        </Popconfirm>
                    </Tooltip>
                </>
            )
        }
    ]

	if(!can('attachments.viewAll'))
		return <PermissionIsRequired title="No tiene acceso a ver listado de roles" />

    return (
        <>
            <Table
                dataSource={attachments}
                rowKey={r => r.id}
                columns={columns}
                size="small"
                loading={!attachments}
                locale={{ emptyText: <Empty description="Sin archivos adjuntos" /> }}
            />        
        </>
    )
}