import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import ServiceForm from '../ServiceForm'
import PermissionIsRequired from '../../../../components/PermissionIsRequired'

import { storeService } from '../../services'
import { can, handleError } from '../../../../helpers'

export default function NewRetryServiceModal({ visible, onClose, service, reload, setCurrentTab }) {
	const { register, handleSubmit, formState: { errors } } = useForm()
	const [loading, setLoading] = useState(false)
	const history = useHistory()

	const onSubmit = values => {
		setLoading(true)
		setCurrentTab('asignacion')
		storeService({
			proposal_id: service.proposal_id,
			initial_service_id: service.id,
			...values,
		})
			.then(res => {
				message.success(res.data.message)
				history.push(`/services/${res.data.data.id}`)
				reload()
			})
			.catch(error => handleError(error, setLoading))
	}

	if(!can('services.create'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para crear servicios" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>
					{ service.business_scope === 'inspeccion' ? 'Abrir Inspección de Cierre' : 'Crear Servicio de Reprueba' }
				</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<ServiceForm 
							register={register} 
							errors={errors} 
							service={service}
							businessScope={service.proposal.format_version.business_scope}
						/>
						<Button color="primary" type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Crear Servicio
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}