import React, {useState} from 'react'
import { Divider } from 'antd'

import EditReviewLogbookModal from '../EditReviewLogbookModal'

import { updateReviewLogbook } from '../../services'
import { handleError, handleSuccess, text } from '../../../../helpers'

export default function ReviewLogbookWellTestsTable({ reviewLogbook, reload }) {
    const [editModal, setEditModal] = useState(null)

    const handleDeleteWellTest = sku => {
        const wellsTests = reviewLogbook.wells_tests.tests.filter(r => r.sku !== sku)
        updateReviewLogbook(reviewLogbook.id, { wells_tests: { tests: wellsTests } })
            .then(res => handleSuccess(res.data.message, reload))
            .catch(error => handleError(error))
    }

    return (
        <>
            <table className='table all_borders compact_table'>
                <tbody className='text-center'>
                    <tr className='text-center'>
                        <td className='bg-light bold' style={{ verticalAlign: 'middle' }} rowSpan={2}>N° Pozo</td>
                        <td className='bg-light bold' colSpan={2}>Antes de la Prueba</td>
                        <td className='bg-light bold' colSpan={2}>Después de la Prueba</td>
                        <td className='bg-light bold' rowSpan={2}>Acciones</td>
                    </tr>
                    <tr className='text-center'>
                        <td className='bg-light bold'>Nivel Freático (m)</td>
                        <td className='bg-light bold'>Contaminación</td>
                        <td className='bg-light bold'>Nivel Freático (m)</td>
                        <td className='bg-light bold'>Contaminación</td>
                    </tr>
                    { (reviewLogbook.wells_tests.tests && reviewLogbook.wells_tests.tests.length > 0) ? (
                        <>
                            { reviewLogbook.wells_tests.tests.map( wellTest => (
                                <tr key={wellTest.sku}>
                                    <td>
                                        {wellTest.sku}
                                    </td>
                                    <td>{text(wellTest.before_level)}</td>
                                    <td>{text(wellTest.before_contamination)}</td>
                                    <td>{text(wellTest.after_level)}</td>
                                    <td>{text(wellTest.after_contamination)}</td>
                                    <td>
                                        <span className='text-link' onClick={() => setEditModal(wellTest)}>
                                            Editar
                                        </span>
                                         <Divider type="vertical" />
                                        <span className='text-link' onClick={() => handleDeleteWellTest(wellTest.sku)}>
                                            Eliminar
                                        </span>
                                    </td>
                                </tr>
                            )) }
                        </>
                    ) : (
                        <tr className='text-center'>
                            <td colSpan={5} className='text-muted'>
                                <em>No se han ingresado registros</em>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            { editModal && (
                <EditReviewLogbookModal 
                    visible
                    onClose={() => setEditModal(null)}
                    reviewLogbook={reviewLogbook}
                    reload={reload}
                    formKey="wellsTests"
                    defaultData={editModal}
                />
            )}
        </>
    )
}