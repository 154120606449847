import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import PermissionIsRequired from '../../../components/PermissionIsRequired'
import UploadAttachment from './UploadAttachment'

import { can } from '../../../helpers'

export default function NewAttachmentModal({ 
    visible, 
    onClose, 
    scope, 
    attachableType, 
    attachableId, 
    onSuccess = () => {}, 
    title = 'Adjuntar Archivo',
    defaultLabel = '',
}) {

    if(!can('attachments.create'))
        return <PermissionIsRequired type="modal" title="No tiene permisos para crear adjuntos" onClose={onClose} />

    return (
        <Modal isOpen={visible}>
            <ModalHeader toggle={onClose}>{title}</ModalHeader>
            <ModalBody>
                <UploadAttachment 
                    defaultLabel={defaultLabel}
                    attachableType={attachableType}
                    attachableId={attachableId}
                    scope={scope}
                    onSuccess={url => {
                        onSuccess(url)
                        onClose()
                    }}
                />
            </ModalBody>
        </Modal>
    )
}