import React, { useState, useEffect }  from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'

import ServiceInspectorDocument from './partials/ServiceInspectorDocument'
import InternetRequired from '../Offline/partials/InternetRequired'

import { publicGetServiceInspector } from './services'
import { handleError } from '../../helpers'

export default function ServiceInspectorPreview(props) {
	const { isConnected } = useSelector(state => state.offline)
	const id = props.id ? props.id : props.match.params.id
	const [serviceInspector, setServiceInspector] = useState(null)

	useEffect(() => {
		!serviceInspector && isConnected && publicGetServiceInspector(id, {
			include: 'user,service.proposal.inquiry.branchSpec.branch.company,formatVersion,service.proposal.inquiry.employee'
		})
			.then(res => setServiceInspector(res.data.data))
			.catch(error => handleError(error))
	}, [serviceInspector, id, isConnected])

	if(!isConnected) return <InternetRequired />

	if(!serviceInspector) return <Spinner />

	return (
		<>
			<Container>
				<ServiceInspectorDocument 
					serviceInspector={serviceInspector} 
				/>
			</Container>
		</>
	)
}

const Container = styled.div`
	padding: 0px 10px;
`