import React, { useState } from 'react'
import { Divider, Popconfirm, Tooltip } from 'antd'
import moment from 'moment'

import NewAttachmentModal from './partials/NewAttachmentModal'

import { deleteAttachment } from './services'
import { handleError, handleSuccess } from '../../helpers'

export default function AttachmentTableColumn({ 
    attachment, 
    reload,
    scope,
    attachableType,
    attachableId,
    title,
    defaultLabel,
}) {
    const [showNewAttachmentModal, setShowNewAttachmentModal] = useState(false)

    const handleDelete = id => {
        deleteAttachment(id)
            .then(res => handleSuccess(res.data.message, reload))
            .catch(error => handleError(error))
    }

    return (
        <>
            { attachment ? (
                <>
                    <Tooltip title={attachment.label}>
                        <a 
                            className='text-link' 
                            target='_blank' 
                            href={attachment.url} 
                            rel='noopener noreferrer'
                        >
                            Descargar
                        </a>
                    </Tooltip>
                    <Divider type="vertical" />
                    <Popconfirm
                        title="¿Seguro desea eliminar?"
                        okText="Eliminar"
                        cancelText="Cancelar"
                        onConfirm={() => handleDelete(attachment.id)}
                        okButtonProps={{ danger:true }}
                    >
                        <span className='text-link'>
                            Eliminar
                        </span>
                    </Popconfirm>
                    <div style={{ lineHeight: '12px' }}>
                        <small className='text-muted'><em>Subido {moment(attachment.created_at).fromNow()}</em></small>
                    </div>
                </>
            ) : (
                <span className='text-link' onClick={() => setShowNewAttachmentModal(true)}>
                    Adjuntar Factura
                </span>
            )}
			{ showNewAttachmentModal && (
				<NewAttachmentModal
					visible
					onClose={() => setShowNewAttachmentModal(false)}
					scope={scope}
					attachableType={attachableType}
					attachableId={attachableId}
					title={title}
                    defaultLabel={defaultLabel}
                    onSuccess={() => {
                        setShowNewAttachmentModal(false)
                        reload()
                    }}
				/>
			)}
        </>
    )
}