import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import DocumentHeader from '../../../../components/DocumentHeader'
import { currency } from '../../../../helpers'

export default function SERVICE_INSPECTOR_TEMPLATE_PRU01({ data }) {
    return (
        <Container>
            <DocumentHeader
				title="ASIGNACIÓN INSPECTOR"
				version={data.format_version.sku}
				expireAt={data.format_version.expire_at}
			/>
            <SectionTitle>ASIGNACIÓN INSPECTOR</SectionTitle>
            <div>
                <table className='table all_borders mb-0'>
                    <tbody>
                        <tr>
                            <td className='bg-light bold'>Inspector Asignado</td>
                            <td colSpan={3}>{data.user.name}</td>
                        </tr>
                        <tr>
                            <td className='bg-light bold'>Fecha envío asignación</td>
                            <td>{moment(data.assigned_at).format('DD-MMM-YYYY').toUpperCase()}</td>
                            <td className='bg-light bold'>Consecutivo asignación</td>
                            <td>ADN-{data.id}-{moment(data.assigned_at).format('MM-YYYY')}</td>
                        </tr>
                        <tr>
                            <td className='bg-light bold' width="200px">Total prestación del servicio</td>
                            <td colSpan={3} className='p-1'>
                                <table className='table all_borders mb-0'>
                                    <tbody>
                                        <tr>
                                            <td className='bg-light bold' width="150px">Servicio de inspección</td>
                                            <td>$ {currency(data.service_value)}</td>
                                            <td className='bg-light bold' width="150px">Gastos de viaje</td>
                                            <td>$ {currency(data.trip_expenses)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td className='bg-light bold'>Código Proceso:</td>
                            <td colSpan={3}>{data.service.proposal.sku}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <SectionTitle>1. INFORMACIÓN DE LA INSTALACIÓN</SectionTitle>
            <div>
                <table className='table all_borders mb-0'>
                    <tbody>
                        <tr>
                            <td width="200px" className='bg-light bold'>Razón Social:</td>
                            <td>{data.service.proposal.inquiry.branch_spec.branch.company.name}</td>
                            <td width="200px" className='bg-light bold'>NIT</td>
                            <td>{data.service.proposal.inquiry.branch_spec.branch.company.complete_document}</td>
                        </tr>
                        <tr>
                            <td className='bg-light bold'>Representante Legal:</td>
                            <td colSpan={3}>{data.service.proposal.inquiry.branch_spec.branch.company.legal_owner_name}</td>
                        </tr>
                        <tr>
                            <td className='bg-light bold'>Nombre de Instalación:</td>
                            <td>{data.service.proposal.inquiry.branch_spec.branch.name}</td>
                            <td className='bg-light bold'>Tipo de Instalación:</td>
                            <td>{data.service.proposal.inquiry.branch_spec.branch.instalation_type_name}</td>
                        </tr>
                        <tr>
                            <td className='bg-light bold'>Correo electrónico</td>
                            <td colSpan={3}>{data.service.proposal.inquiry.branch_spec.branch.email}</td>
                        </tr>
                        <tr>
                            <td className='bg-light bold'>Dirección</td>
                            <td>{data.service.proposal.inquiry.branch_spec.branch.address_line}</td>
                            <td className='bg-light bold'>Ciudad</td>
                            <td>{data.service.proposal.inquiry.branch_spec.branch.address_state}, {data.service.proposal.inquiry.branch_spec.branch.address_city}</td>
                        </tr>
                        <tr>
                            <td className='bg-light bold'>Teléfono</td>
                            <td>{data.service.proposal.inquiry.branch_spec.branch.phone}</td>
                            <td className='bg-light bold'>Celular</td>
                            <td>{data.service.proposal.inquiry.branch_spec.branch.mobile}</td>
                        </tr>
                        <tr>
                            <td className='bg-light bold'>Responsable de atender el servicio:</td>
                            <td>{data.service.proposal.inquiry.employee.name}</td>
                            <td className='bg-light bold'>Celular Responsable</td>
                            <td>{data.service.proposal.inquiry.employee.mobile}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <SectionTitle>2. INFORMACIÓN DE LA INSPECCIÓN</SectionTitle>
            <div>
                <table className='table all_border mb-0'>
                    <tbody>
                        <tr>
                            <td className='bg-light bold'>Número de tanques*</td>
                            <td>{data.test_details.total_tanks}</td>
                            <td className='bg-light bold'>Altura aproximada del desfogue*</td>
                            <td>{data.test_details.aprox_altitude}</td>
                            <td className='bg-light bold'>Válvula para independizar desfogue*</td>
                            <td>{data.test_details.valve_present}</td>
                        </tr>
                        <tr>
                            <td className='bg-light bold'>Criterio:</td>
                            <td colSpan={5}>{data.service.criteria}</td>
                        </tr>
                        <tr>
                            <td className='bg-light bold'>Inspección</td>
                            <td colSpan={5} className='p-1'>
                                <table className='table all_border mb-0'>
                                    <tbody>
                                        <tr className='text-center'>
                                            <td className='bg-light bold'>Lugar</td>
                                            <td className='bg-light bold'>Horas Aprox.</td>
                                            <td className='bg-light bold'>Fecha</td>
                                        </tr>
                                        <tr className='text-center'>
                                            <td>{data.service.proposal.inquiry.branch_spec.branch.address_line}</td>
                                            <td>{data.service.hours_estimate}</td>
                                            <td>{moment(data.service.start_at).format('DD-MMM-YYYY').toUpperCase()}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td className='bg-light bold'>Observadores**:</td>
                            <td colSpan={5}>{data.service.observers}</td>
                        </tr>
                    </tbody>
                </table>
                <div className='p-3 pb-0'>
                    <small>*Información diligenciada en la Solicitud de servicio y que corresponde a la propuesta comercial del proceso <br />
                    ** Acompañaran las actividades de inspección, pero no es parte de estas, y no influencian ni interfieren con la realización de la inspección ni los hallazgos ni conclusiones de esta.</small>
                </div>
            </div>
            <SectionTitle>3. OBSERVACIONES ESPECÍFICAS:</SectionTitle>
            <SectionContent>{data.observations}</SectionContent>
            <SectionTitle>4. ESPACIO PARA SER DILIGENCIADO POR EL INSPECTOR</SectionTitle>
            <SectionContent>
                <p className='mb-15 text-center mt-10'>Aceptación del Inspector: <strong>{ data.accepted_at ? moment(data.accepted_at).format('DD-MM-YYY') : <em>Sin aceptación aún</em> }</strong><br/></p>
                <strong>DECLARACIÓN DE INDEPENDENCIA, IMPARCIALIDAD, CONFIDENCIALIDAD Y EXONERACIÓN DE CONFLICTO DE INTERÉS:</strong>
            </SectionContent>
            <SectionContent className="print-page-break">
                En calidad de inspector asignado a la actividad que soporta el presente documento, mayor de edad e identificado con la cedula de ciudadanía relacionada, declaro de hasta dos (2) años previos a la realización de la presente inspección, NO he realizado actividades de: <br /><br />
                Diseño, fabricación, suministro, instalación, compra, posesión, o mantenimiento de revestimiento de tanques y/o tuberías.<br />
                También declaro que en el proceso de inspección seré imparcial y manejaré la información del proceso de manera confidencial con lo cual me comprometo a seguir los lineamientos establecidos por el Organismo evaluador de conformidad DEWAR S.A.S., para el desarrollo del proceso de inspección tales como procedimientos, guías y formularios con el fin de no poner en riesgo el nombre de la firma que represento.<br />
                Así mismo, declaró no presentar conflicto de interés con el procedimiento que me ha sido asignado como inspector, y me comprometo a su realización bajo el cumplimiento de los procedimientos del DEWAR como organismo de inspección y el Reglamento de Inspección.<br /><br />
                En caso no de aceptación especificar el motivo.
            </SectionContent>
            <table className='table all_borders mb-10'>
                <tbody>
                    <tr>
                        <td width="190px">Nombre del inspector:</td>
                        <td>{data.user.name}</td>
                    </tr>
                </tbody>
            </table>
            <div className='p-5'>
                Por favor con máximo dos (2) días de recibido este documento, enviar correo electrónico a la Coordinación de programación para confirmar recibo y aceptación de la asignación, de ser aceptada deberá tener presente para la realización de la misma, el procedimiento de pruebas de hermeticidad y el Reglamento de Inspección.
                <br /><br /><br />
                Atentamente,
                <br /><br />
                <strong>Coordinadora de programación<br />
                DEWAR – OIN</strong>
            </div>

        </Container>
    )
}

const Container = styled.div`
    font-family: 'Arial';
	font-size: 13px;
	line-height: 15px;
	width: 21.59cm;
	min-height: 27.94cm;
	margin 0 auto;
	background-color: white;
	padding: 0.5cm;
	overflow: hidden;
	& table tr td {
		border: 1px solid black;
		padding: 5px 3px;
	}
    background-color: #fff;
    span.variable {
        color: #e78035;
        font-weight: 600;
    }
    h5 {
        font-size: 16px;
        font-weight: bold;
    }
`

const SectionTitle = styled.div`
	font-weight: bold;
	padding: 2px 6px;
	margin: 15px 0px 5px;
	color: black;
	text-align: ${props => props.center ? 'center' : 'left'};
`

const SectionContent = styled.div`
	padding: 5px;
	border: 1px solid black;
	color: black;
`