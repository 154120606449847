import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'
import { Descriptions } from 'antd'
import moment from 'moment'

import RelatedServiceModal from './modals/RelatedServiceModal'
import BranchDetails from '../../Branches/partials/BranchDetails'
import CompanyDetails from '../../Companies/partials/CompanyDetails'

const { Item } = Descriptions

export default function ServiceProfileDetails({ service, reload = null }) {
	const user = useSelector(state => state.auth.user)
	const [showRelatedServiceModal, setShowRelatedServiceModal] = useState(false)
	const { isConnected } = useSelector(state => state.offline)

	const branch = service.proposal.inquiry.branch_spec.branch
	const company = branch.company

	return (
		<>

			<Row>
				<Col sm="6" md="4">
					<Card>
						<CardHeader>Detalles Generales</CardHeader>
						<CardBody>
							<Descriptions column={1} size="small" className='labelBold'>
								<Item label="Propuesta Comercial">{service.proposal.sku}</Item>
								<Item label="Fecha">{moment(service.start_at).format('DD-MMM-YYYY').toUpperCase()}</Item>
								{ service.business_scope === 'inspeccion' && (
									<Item label="Tipo de Inspección">{service.type}</Item>
								)}
								<Item label="Horas Estimadas">{service.hours_estimate}</Item>
								<Item label="Criterio">{service.criteria}</Item>
								{ user.is_super_admin && (
									<>
										<Item label="Solicitud de Servicio">
											<Link to={`/inquiries/preview/${service.proposal.inquiry.id}`}>Abrir Formato Solicitud #{service.proposal.inquiry.id}</Link>
										</Item>
										<Item label="Propuesta Comercial">
											<Link to={`/proposals/${service.proposal.id}`}>Abrir {service.proposal.sku}</Link>
										</Item>
									</>
								)}
								{ isConnected && reload !== null && (
									<Item label="Servicio Relacionado">
										<span>
											{service.related_service_id ? `Servicio #${service.related_service_id}` : <em className='small'>Sin servicio relacionado</em>}
											<br />
											<span className='text-link' onClick={() => setShowRelatedServiceModal(true)}>
												Asignar Servicio Relacionado
											</span>
										</span>
									</Item>
								)}
							</Descriptions>
						</CardBody>
					</Card>
				</Col>
				<Col sm="6" md="4">
					<Card>
						<CardHeader>Empresa Solicitante</CardHeader>
						<CardBody>
							<CompanyDetails company={company} listVersion />
						</CardBody>
					</Card>
				</Col>
				<Col sm="6" md="4">
					<Card>
						<CardHeader>Instalación a Inspeccionar</CardHeader>
						<CardBody>
							<BranchDetails branch={branch} listVersion />
						</CardBody>
					</Card>
				</Col>
			</Row>
			{ showRelatedServiceModal && (
				<RelatedServiceModal 
					visible
					onClose={() => setShowRelatedServiceModal(false)}
					serviceId={service.id}
					companyId={company.id}
					reload={reload}
				/>
			)}
		</>
	)
}