import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Card, CardBody, CardHeader, CardTitle, CardSubtitle } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import NewRoleForm from './partials/NewRoleForm'
import RolesTable from './partials/RolesTable'
import InternetRequired from '../Offline/partials/InternetRequired'

import { getRoles } from './services'
import { can, handleError } from '../../helpers'

export default function RolesList() {
	const { isConnected } = useSelector(state => state.offline)
	const [roles, setRoles] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')

	useEffect(()=>{
		!roles && isConnected && can('roles.viewAll') && getRoles({
			include: 'permissionsCount,usersCount,permissions',
			[`filter[${filterType}]`]: filterValue
		})
			.then(res => setRoles(res.data.data))
			.catch(error => handleError(error))
	}, [roles, filterType, filterValue, isConnected])

	if(!isConnected) return <InternetRequired />

	return (
		<>
			<Row>
				<Col md="3">
					<Card>
						<CardHeader>
							<CardTitle>Agregar Rol Nuevo</CardTitle>
							<CardSubtitle>Ingrese los datos del fomulario</CardSubtitle>
						</CardHeader>
						<CardBody>
							<NewRoleForm reload={()=>setRoles(null)} />
						</CardBody>
					</Card>
				</Col>
				<Col md="9">
					<ListTopBar 
						filterType={filterType}
						setFilterType={setFilterType}
						filterValue={filterValue}
						setFilterValue={setFilterValue}
						title="Roles y Permisos"
						subtitle="Roles y permisos de plataforma Dewar"
						reload={() => setRoles(null)}
						options={[
							{ label: 'Buscar por nombre', value: 'name' },
						]}
					/>
					<Card>
						<CardBody>
							<RolesTable 
                                roles={roles} 
                                reload={()=>setRoles(null)} 
                            />
						</CardBody>
					</Card>
				</Col>
			</Row>			
		</>
	)
}