import React, { useState, useEffect } from 'react'
import { Button, Spinner } from 'reactstrap'

import PreInvoicesTable from './partials/PreInvoicesTable'
import NewPreInvoiceModal from './partials/NewPreInvoiceModal'

import { getPreInvoices } from './services'
import { can, handleError } from '../../helpers'

export default function ProposalPreInvoicesList({ proposalId, reload }) {
	const [preInvoices, setPreInvoices] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!preInvoices && can('pre_invoices.viewAll') && getPreInvoices({ 
			'filter[proposal_id]': proposalId,
			include: 'invoiceAttachment'
		})
			.then(res => setPreInvoices(res.data.data))
			.catch(error => handleError(error))
	}, [preInvoices, proposalId])

	return (
		<>
			{ preInvoices ?  (
				<>
					<PreInvoicesTable 
						preInvoices={preInvoices} 
						reload={() => {
							setPreInvoices(null)
							reload()
						}} 
					/>
					<Button className='float-right mt-10' color='primary' onClick={() => setShowNewModal(true)}>
						Agregar Cuenta de Cobro
					</Button>
				</>
			) : <Spinner />}
			{ showNewModal && (
				<NewPreInvoiceModal 
					visible
					onClose={() => setShowNewModal(false)}
					proposalId={proposalId}
				/>
			)}
		</>
	)
}