import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import ReportDefaultEvidenceTable from './partials/ReportDefaultEvidenceTable'
import NewReportDefaultEvidenceForm from './partials/NewReportDefaultEvidenceForm'

import { getReportDefaultEvidence } from './services'
import { can, handleError } from '../../helpers'

export default function ReportDefaultEvidencesModal({ visible, onClose, instalationType }) {
	const [reportDefaultEvidences, setReportDefaultEvidences] = useState(null)

	useEffect(() => {
		!reportDefaultEvidences && can('report_default_evidence.viewAll') && getReportDefaultEvidence({
			'filter[instalation_type_id]': instalationType.id,
			include: 'evidenceTemplate'
		})
			.then(res => setReportDefaultEvidences(res.data.data))
			.catch(error => handleError(error))
	}, [ reportDefaultEvidences, instalationType ])

	return (
		<Modal isOpen={visible} size="lg">
			<ModalHeader toggle={onClose}>Evidencia por defecto en Informe</ModalHeader>
			<ModalBody>
				<ReportDefaultEvidenceTable 
					reportDefaultEvidences={reportDefaultEvidences}
					reload={() => setReportDefaultEvidences(null)}
				/>
				<NewReportDefaultEvidenceForm 
					instalationType={instalationType}
					reload={() => setReportDefaultEvidences(null)}
				/>
			</ModalBody>
		</Modal>
	)
}