import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'

import PermissionIsRequired from '../../../../components/PermissionIsRequired'

import { updateServiceReport } from '../../services'
import { can, handleError, handleSuccess } from '../../../../helpers'

export default function EditServiceReportModal({ visible, onClose, serviceReport, reload }) {
	const { register, handleSubmit, formState: { errors } } = useForm()

	const onSubmit = values => {
		updateServiceReport(serviceReport.id, values)
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

	if(!can('service_reports.update'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar informes de inspección" onClose={onClose} />

	return (
		<Modal isOpen={visible}>
			<ModalHeader toggle={onClose}>Modificar Resultados</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Group>
						<Form.Label>Resultado Final Director Técnico <span className='text-danger'>*</span></Form.Label>
						<Form.Control
							as="select"
							{...register('is_director_compliant', { required: serviceReport.is_director_compliant !== null ? false : true })}
							defaultValue={serviceReport && serviceReport.is_director_compliant ? 1 : 0}
						>
							<option value="">:: Seleccione una opción ::</option>
							<option value="1">Conforme</option>
							<option value="0">No Conforme</option>
						</Form.Control>
						{ errors.is_director_compliant && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Observaciones Director Técnico <small>(opcional)</small></Form.Label>
						<Form.Control 
							as="textarea"
							{...register('director_observations')}
							defaultValue={serviceReport && serviceReport.director_observations}
							placeholder='Escriba aquí...'
							style={{ height: '70px' }}
						/>
					</Form.Group>
					<Form.Group>
						<Form.Label>Resultado Decisión Comité Técnico<span className='text-danger'>*</span></Form.Label>
						<Form.Control
							as="select"
							{...register('is_committee_compliant', { required: serviceReport.is_committee_compliant !== null ? false : true })}
							defaultValue={serviceReport && serviceReport.is_committee_compliant ? '1' : '0'}
						>
							<option value="">:: Seleccione una opción ::</option>
							<option value="1">Conforme</option>
							<option value="0">No Conforme</option>
						</Form.Control>
						{ errors.is_committee_compliant && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Observaciones Comité Técnico <small>(opcional)</small></Form.Label>
						<Form.Control 
							as="textarea"
							{...register('committee_observations')}
							defaultValue={serviceReport && serviceReport.committee_observations}
							placeholder='Escriba aquí...'
							style={{ height: '70px' }}
						/>
					</Form.Group>
					<Button color="primary" type="submit">Actualizar Resultado Final</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}