import React, { useState } from 'react'
import { Empty, Table, Tooltip, Divider, Popconfirm } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import EditPlanTaskModal from './EditPlanTaskModal'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { deletePlanTask } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function PlanTasksTable({ planTasks, reload, editable }) {
	const [editPlanTask, setEditPlanTask] = useState(null)
	
	const columns = [
		{
			title: 'Día',
			dataIndex: 'day_name',
			width: '120px'
		},
		{
			title: 'Hora',
			dataIndex: 'time',
			width: '120px',
			render: t => moment(`01-01-2022 ${t}`).format('hh:mm A')
		},
		{
			title: 'Requisito / Actividad por evaluar',
			dataIndex: 'name'
		},
		{
			title: 'Acciones',
			render: (t,r) => editable ? (
				<>
					<Tooltip title="Editar">
						<div className='inline' onClick={() => setEditPlanTask(r)}>
							<FontAwesomeIcon className='text-link' icon={faEdit} />
						</div>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Eliminar">
						<Popconfirm 
							title="¿Desea eliminar actividad?"
							okText="Eliminar"
							cancelText="Cancelar"
							onConfirm={() => handleDelete(r.id)}
							okButtonProps={{ danger:true }}
						>
							<FontAwesomeIcon className="text-link" icon={faTrash} />{" "}
						</Popconfirm>
					</Tooltip>
				</>
			) : null
		}
	]

	const handleDelete = id => {
		can('plan_tasks.delete') && deletePlanTask(id)
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

	if(!can('plan_tasks.viewAll'))
		return <PermissionIsRequired title="No tiene acceso a ver listado de actividades" />
	
	return (
		<>
			<Table 
				dataSource={planTasks}
				columns={columns}
				rowKey={r => r.id}
				size="small"
				className='mb-10'
				locale={{ emptyText: (
					<Empty 
						description='No tiene actividades asociadas al plan' 
						imageStyle={{ height: '70px' }}
					/> 
				)}}
			/>
			{ editPlanTask && (
				<EditPlanTaskModal 
					visible
					onClose={() => setEditPlanTask(null)}
					planTask={editPlanTask}
					reload={reload}
				/>
			)}
		</>
	)
}