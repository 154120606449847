import React, { useState } from 'react'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { useHistory } from 'react-router'
import styled from 'styled-components'

import ImportInquiriesModal from './ImportInquiriesModal'
import PermissionIsRequired from '../../../../components/PermissionIsRequired'

import { can } from '../../../../helpers'

export default function NewInquiryModal({ visible, onClose, reload }) {
	const history = useHistory()
	const [showImportModal, setShowImportModal] = useState(false)

	if(!can('inquiries.create'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para crear solicitudes" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Crear Nueva Solicitud</ModalHeader>
				<ModalBody>
					<Row>
						<Col>
							<MenuContainer onClick={() => history.push('/inquiries/wizard')}>
								Crear Manualmente
							</MenuContainer>
						</Col>
						<Col>
							<MenuContainer onClick={() => setShowImportModal(true)}>
								Importar desde CSV
							</MenuContainer>
						</Col>
					</Row>
				</ModalBody>
			</Modal>
			{ showImportModal && (
				<ImportInquiriesModal 
					visible
					onClose={() => {
						setShowImportModal(false)
						reload()
						onClose()
					}}
				/>
			)}
		</>
	)
}

const MenuContainer = styled.div`
	padding: 50px 10px;
	text-align: center;
	background-color: #f0f0f0;
	font-size: 16px;
	&:hover {
		cursor: pointer;
		background-color: #333 !important;
		color: #fff;
	}
`