import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import moment from 'moment'

import EditProposalForm from '../forms/EditProposalForm'
import PermissionIsRequired from '../../../../components/PermissionIsRequired'

import { updateProposal } from '../../services'
import { can, handleError, handleSuccess } from '../../../../helpers'

export default function EditProposalModal({ visible, onClose, proposal, reload }) {
    const isInspection = proposal.format_version.business_scope === 'inspeccion'
    const [loading, setLoading] = useState(false)
    const { register, handleSubmit, formState: { errors }, watch } = useForm({
        defaultValues: {
            elaborated_at: moment(proposal.elaborated_at).format('YYYY-MM-DD'),
            service_value: proposal.service_value,
            quantity: proposal.quantity,
            trip_expenses_value: proposal.trip_expenses_value,
            price_terms: proposal.price_terms,
            is_ds_construction: proposal.is_ds_construction ? 1 : 0
        }
    })

    const onSubmit = values => {
        setLoading(true)
        updateProposal(proposal.id, values)
            .then(res => handleSuccess(res.data.message, reload))
            .catch(error => handleError(error, setLoading))
    }

    if(!can('proposals.update'))
        return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar propuestas" onClose={onClose} />

    return (
        <>
            <Modal isOpen={visible} size='md'>
                <ModalHeader toggle={onClose}>Editar Propuesta {proposal.sku}</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <EditProposalForm 
                            register={register}
                            errors={errors}
                            watch={watch}
                            isInspection={isInspection}
                        />
                        <Button color="primary" type="submit" className='mt-15' disabled={loading}>
                            {loading && <Spinner animation='border' size="sm" />}{" "}
                            Actualizar Propuesta
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}