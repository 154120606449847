import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Badge, Col, Row } from 'reactstrap'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faFilePdf, faToggleOff, faToggleOn, faTrash, faUnlink } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import EditBranchModal from './EditBranchModal'
import BranchPortalAccessModal from './BranchPortalAccessModal'
import AttachmentsListModal from '../../Attachments/AttachmentsListModal'
import Pagination from '../../../components/Pagination'

import { deleteBranch, updateBranch, detachBranchEmployee } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

export default function BranchesTable({ branches, reload, pagination, setPagination, employee }) {
	const currentUser = useSelector(state => state.auth.user)
	const [editBranch, setEditBranch] = useState(null)
	const [branchAttachments, setBranchAttachments] = useState(null)
	const [branchPortalAccess, setBranchPortalAccess] = useState(null)
	
	const columns = [
		{
			title: 'Empresa',
			dataIndex: 'company',
			render: t => t.name
		},
		{
			title: 'Nombre',
			dataIndex: 'name',
		},
		{
			title: 'Tipo de Instalación',
			dataIndex: 'instalation_type',
			render: t => t.name
		},
		{
			title: 'Ciudad',
			dataIndex: 'address_city',
		},
		{
			title: 'Estado',
			dataIndex: 'disabled_at',
			render: t => <Badge color={t ? 'dark' : 'info'}>{t ? 'Inactiva' : 'Activa'}</Badge>
		},
		{
			title: 'Portal Clientes',
			dataIndex: 'portal_is_active',
			render: (t, r) => (
				<Badge
					color={t ? 'success' : 'dark'}
					onClick={()=> currentUser.is_super_admin ? setBranchPortalAccess(r) : message.warning('Funcionalidad únicamente habilitada para administradores')}
					style={{ cursor: 'pointer' }}
				>
					{t ? 'Con acceso' : 'Sin acceso'}
				</Badge>
			)
		},
		{
			title: 'Acciones',
			width: '130px',
			render: (t, record) => (
				<>
					<Tooltip title="Editar">
						<div className='inline' onClick={()=>setEditBranch(record)}>
							<FontAwesomeIcon className="text-link" icon={faEdit} />
						</div>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Adjuntos">
						<div className='inline text-link' onClick={()=>setBranchAttachments(record)}>
							<FontAwesomeIcon icon={faFilePdf} />{" "}
						</div>
					</Tooltip>
					{ employee && (
						<>
							<Divider type="vertical" />
							<Tooltip title="Desasociar Sede">
								<Popconfirm
									title="¿Desea desasociar la sede?"
									okText="Desasociar"
									cancelText="Cancelar"
									okButtonProps={{ danger:true }}
									onConfirm={() => handleDetach(record.id)}
								>
									<FontAwesomeIcon className="text-link" icon={faUnlink} />
								</Popconfirm>
							</Tooltip>
						</>
					)}
					{ currentUser.is_super_admin && !employee && (
						<>
							<Divider type="vertical" />
							<Tooltip title={!record.disabled_at ? 'Desactivar' : 'Reactivar'}>
								<Popconfirm 
									title={`¿Desea ${!record.disabled_at ? 'desactivar' : 'reactivar'} la sede?`}
									okText={!record.disabled_at ? 'Desactivar' : 'Reactivar'}
									cancelText="Cancelar"
									onConfirm={() => handleToggle(record)}
									okButtonProps={{ danger:true }}
								>
									<FontAwesomeIcon 
										className="text-link" 
										icon={!record.disabled_at ? faToggleOn : faToggleOff} 
									/>
								</Popconfirm>
							</Tooltip>
							<Divider type="vertical" />
							<Popconfirm 
								title="¿Desea eliminar la sede?"
								okText="Eliminar"
								cancelText="Cancelar"
								onConfirm={() => handleDelete(record.id)}
								okButtonProps={{ danger:true }}
							>
								<FontAwesomeIcon className="text-link" icon={faTrash} />
							</Popconfirm>
						</>
					)}
				</>
			)
		}
	]

	const expandedRow = r => (
		<>
			<p className='mb-5'><strong>Dirección:</strong> {r.complete_address}</p>
			<p className='mb-5'><strong>Personal Asociado:</strong> 
				{ r.employees.length > 0 ? r.employees.map(e => e.name).join(', ') : <em className='hint-text'>Sin personal asociado</em> }
			</p>
			<Row>
				<Col>
					<p className='mb-5'><strong>Email:</strong> {r.email ? r.email : <em className='hint-text'>Sin email registrado</em>}</p>
					<p className='mb-5'><strong>Correo Electrónico #2: </strong>{r.email_secundary}</p>
					<p className='mb-5'><strong>Correo Electrónico #3: </strong>{r.email_terciary}</p>
					<p className='mb-5'><strong>Celular:</strong> {r.mobile}</p>
				</Col>
				<Col>
					<p className='mb-5'><strong>Bandera:</strong> {r.flag}</p>
					<p className='mb-5'><strong>SICOM:</strong> {r.sicom_code}</p>
					<p className='mb-5'><strong>Constructor:</strong> {r.constructor_name ? r.constructor_name : <em className='hint-text'>Sin nombre de constructor registrado</em>}</p>
					<p className='mb-5'><strong>Fijo:</strong> {r.phone ? r.phone : <em className='hint-text'>Sin teléfono fijo registrado</em>}</p>
				</Col>
			</Row>
		</>
	)

	const handleDelete = id => {
		deleteBranch(id)
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

	const handleToggle = branch => {
		updateBranch(branch.id, {
			disabled_at: !branch.disabled_at ? moment().format('YYYY-MM-DD HH:mm:ss') : ''
		})
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

	const handleDetach = branchId => {
		detachBranchEmployee(branchId, { employee_id: employee.id })
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

	if(!can('branches.viewAll'))
		return <PermissionIsRequired title="No tiene acceso a ver listado de sedes" />
	
	return (
		<>
			<Table 
				rowKey={r => r.id}
				dataSource={branches}
				size='small'
				columns={columns}
				expandable={{ expandedRowRender: expandedRow }}
				pagination={false}
				className="mb-15"
				locale={{ emptyText: <Empty description="No se encontraron sedes" imageStyle={{ height: '70px' }} /> }}
			/>
			<Pagination
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			{ editBranch && (
				<EditBranchModal 
					visible
					onClose={() => setEditBranch(null)}
					branch={editBranch}
					reload={reload}
				/>
			)}
			{ branchPortalAccess && (
				<BranchPortalAccessModal 
					visible
					onClose={() => setBranchPortalAccess(null)}
					branch={branchPortalAccess}
					reload={reload}
				/>
			)}
			{ branchAttachments && (
				<AttachmentsListModal
					visible
					onClose={() => setBranchAttachments(null)}
					scope="documents"
					attachableType="branch"
					attachableId={branchAttachments.id}
					title={`Adjuntos de ${branchAttachments.name}`}
				/>
			)}
		</>
	)
}