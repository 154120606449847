import React from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'

import EvidenceTemplateForm from './EvidenceTemplateForm'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { updateEvidenceTemplate } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function EditEvidenceTemplateModal({ visible, onClose, evidenceTemplate, reload }) {
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: { 
			item: evidenceTemplate.item,
			type: evidenceTemplate.type,
			name: evidenceTemplate.name,
		}
	})

	const onSubmit = values => {
		updateEvidenceTemplate(evidenceTemplate.id, values)
			.then(res => handleSuccess(res.data.message, reload, onClose))
			.catch(error => handleError(error))
	}

	if(!can('evidence_templates.update'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar plantillas de evidencia" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Actualizar Plantilla</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<EvidenceTemplateForm register={register} errors={errors} onlyEditable />
						<Button color="primary" type="submit">Actualizar Plantilla</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}