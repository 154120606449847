import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditTankModal from './EditTankModal'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { deleteTank } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function TanksTable({ tanks, reload }) {
	const currentUser = useSelector(state => state.auth.user)
	const [editTank, setEditTank] = useState(null)

	const handleDelete = id => {
		can('tanks.delete') && deleteTank(id)
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}
	
	const columns = [
		{
			title: 'Número',
			dataIndex: 'tank_number',
		},
		{
			title: 'GASOLINA CORRIENTE',
			dataIndex: 'total_corriente',
			render: t => `${t} GAL`
		},
		{
			title: 'GASOLINA EXTRA',
			dataIndex: 'total_extra',
			render: t => `${t} GAL`
		},
		{
			title: 'ACPM / DIESEL / BIODIESEL',
			dataIndex: 'total_disel',
			render: t => `${t} GAL`
		},
		{
			title: 'NAFTA',
			dataIndex: 'total_nafta',
			render: t => `${t} GAL`
		},
		{
			title: 'CRUDO Y MEZCLAS',
			dataIndex: 'total_crudo',
			render: t => `${t} GAL`
		},
		{
			title: 'FUEL OIL',
			dataIndex: 'total_oil',
			render: t => `${t} GAL`
		},
		{
			title: 'JET A',
			dataIndex: 'total_jet',
			render: t => `${t} GAL`
		},
		{
			title: 'AVGAS',
			dataIndex: 'total_avgas',
			render: t => `${t} GAL`
		},
		{
			title: 'ETANOL',
			dataIndex: 'total_etanol',
			render: t => `${t} GAL`
		},
		{
			title: 'OTRO',
			dataIndex: 'total_aceite',
			render: (t, r) => `${t} GAL ${r.other_product ? r.other_product : ''}`
		},
		{
			title: '',
			render: (t, record) => reload ? (
				<>
					<Tooltip title="Editar">
						<div className='inline' onClick={()=>setEditTank(record)}>
							<FontAwesomeIcon className="text-link" icon={faEdit} />
						</div>
					</Tooltip>
					{ currentUser.is_super_admin && (
						<>
							<Divider type="vertical" />
							<Popconfirm 
								title="¿Desea eliminar el tanque?"
								okText="Eliminar"
								cancelText="Cancelar"
								onConfirm={() => handleDelete(record.id)}
								okButtonProps={{ danger:true }}
							>
								<FontAwesomeIcon className="text-link" icon={faTrash} />
							</Popconfirm>
						</>
					)}
				</>
			) : ''
		}
	]

	if(!can('tanks.viewAll'))
		return <PermissionIsRequired title="No tiene permisos para ver los tanques" />
	
	return (
		<>
			<Table 
				rowKey={r => r.id}
				dataSource={tanks}
				size='small'
				columns={columns}
				className='mb-10'
			/>
			{ editTank && (
				<EditTankModal 
					visible
					onClose={() => setEditTank(null)}
					tank={editTank}
					reload={reload}
				/>
			)}
		</>
	)
}