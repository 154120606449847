import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import NewInsCertificateForm from '../forms/NewInsCertificateForm'
import NewTestCertificateForm from '../forms/NewTestCertificateForm'
import PermissionIsRequired from '../../../../components/PermissionIsRequired'

import { can } from '../../../../helpers'

export default function NewCertificateModal({ visible, onClose, businessScope , serviceVeredictId, service, reload }) {
	const formProps = {
		serviceVeredictId: serviceVeredictId,
		criteria: service.criteria,
		branch: service.proposal.inquiry.branch_spec.branch,
		reload: reload,
	}

	if(!can('certificates.create'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para crear certificados" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Generar Nuevo Certificado</ModalHeader>
				<ModalBody>
					{ businessScope === 'inspeccion' && (
						<NewInsCertificateForm {...formProps} />
					)}
					{ businessScope === 'pruebas' && (
						<NewTestCertificateForm {...formProps} />
					)}
				</ModalBody>
			</Modal>
		</>
	)
}