import React from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import moment from 'moment'

import ServiceForm from '../ServiceForm'
import PermissionIsRequired from '../../../../components/PermissionIsRequired'

import { updateService } from '../../services'
import { can, handleError, handleSuccess } from '../../../../helpers'

export default function EditServiceModal({ visible, onClose, service, reload }) {
	const { register, handleSubmit, formState: { errors } } = useForm({
		defaultValues: {
			hours_estimate: service.hours_estimate,
			start_at: moment(service.start_at).format('YYYY-MM-DD'),
			observers: service.observers
		}
	})
	
	const onSubmit = values => {
		updateService(service.id, values)
			.then(res => handleSuccess(res.data.message, reload, onClose))
			.catch(error => handleError(error))
	}

	if(!can('services.update'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar servicios" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Actualizar Servicio</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<ServiceForm
							register={register} 
							errors={errors} 
						/>
						<Button color="primary" type="submit">Actualizar Registro</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}