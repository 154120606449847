import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import UserForm from './UserForm'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { updateUser } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function EditUserModal({ visible, onClose, user, reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()

	const onSubmit = values => {
		if(parseInt(values.document) === parseInt(user.document)) delete values.document
		if(values.email === user.email) delete values.email
		updateUser(user.id, values)
			.then(res => handleSuccess(res.data.message, reload, handleClose))
			.catch(error => handleError(error))
	}	

	const handleClose = () => {
		reset()
		onClose()
	}

	if(!can('users.update'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar usuarios" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible} toggle={handleClose}>
				<ModalHeader toggle={handleClose}>Actualizar Usuario</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<UserForm 
							user={user} 
							register={register} 
							errors={errors}
						/>
						<Button type="submit" color="primary">Actualizar Usuario</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}