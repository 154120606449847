import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, Col, Row, Spinner } from 'reactstrap'
import { Tabs } from 'antd'

import CompanyDetails from '../Companies/partials/CompanyDetails'
import BranchDetails from '../Branches/partials/BranchDetails'
import ProposalProfileTopBar from './partials/ProposalProfileTopBar'

import ProposalDetailsTab from './partials/tabs/ProposalDetailsTab'
import ProposalPreInvoicesTab from './partials/tabs/ProposalPreInvoicesTab'
import ProposalAcceptanceTab from './partials/tabs/ProposalAcceptanceTab'
import ProposalPaymentReceiptsTab from './partials/tabs/ProposalPaymentReceiptsTab'
import ProposalServicesTab from './partials/tabs/ProposalServicesTab'

import { getProposal } from './services'
import { can, handleError } from '../../helpers'
import PermissionIsRequired from '../../components/PermissionIsRequired'

export default function ProposalProfile(props) {
    const id = props.id ? props.id : props.match.params.id
    const { isConnected } = useSelector(state => state.offline)
    const [proposal, setProposal] = useState(null)
    const [currentTab, setCurrentTab] = useState('details')

    useEffect(() => {
        !proposal && isConnected && can('proposals.view') && getProposal(id, {
            include: 'formatVersion,inquiry,preInvoices,services.serviceInspector,inquiry.employee,paymentReceipts,inquiry.branchSpec.branch.instalationType,inquiry.branchSpec.branch.company',
        })
            .then(res => setProposal(res.data.data))
            .catch(error => handleError(error))
    }, [ proposal, id, isConnected ])

    useEffect(() => {
        if(proposal && parseInt(id) !== proposal.id)
            setProposal(null)
    }, [ proposal, id ])

    const tabProps = {
        proposal,
        reload: () => setProposal(null),
    }

    const tabs = [
        {
            key: 'details',
            label: '1. Datos de la Propuesta',
            disabled: !proposal,
            children: <ProposalDetailsTab {...tabProps} />,
        },
        {
            key: 'pre_invoices',
            label: '2. Cuentas de Cobro',
            disabled: !proposal,
            children: <ProposalPreInvoicesTab {...tabProps} />,
        },
        {
            key: 'acceptance',
            label: '3. Aceptación de la Propuesta',
            disabled: !proposal,
            children: <ProposalAcceptanceTab {...tabProps} />,
        },
        {
            key: 'payment_receipts',
            label: '4. Soporte de Pagos',
            disabled: !proposal,
            children: <ProposalPaymentReceiptsTab {...tabProps} />,
        },
        {
            key: 'services',
            label: '5. Servicios Asociados',
            disabled: !proposal,
            children: <ProposalServicesTab {...tabProps} />,
        },
    ]

    if(!can('proposals.view'))
        return <PermissionIsRequired title="No tiene permisos para ver perfil de propuestas comerciales" />

    if(!proposal)
        return <Spinner />

    return (
        <>
            <ProposalProfileTopBar 
                proposal={proposal} 
                reload={() => setProposal(null)}
            />
            <Card>
                <CardBody>
                    <Tabs
                        type="card"
                        tabPosition='left'
                        activeKey={currentTab}
                        onChange={key => setCurrentTab(key)}
                        items={tabs}
                    />
                </CardBody>
            </Card>
            <Row>
				<Col sm="6">
					<Card>
						<CardHeader>Empresa Solicitante</CardHeader>
						<CardBody>
							<CompanyDetails 
                                listVersion 
                                company={proposal.inquiry.branch_spec.branch.company}
                            />
						</CardBody>
					</Card>
				</Col>
				<Col sm="6">
					<Card>
						<CardHeader>Instalación a Inspeccionar</CardHeader>
						<CardBody>
							<BranchDetails 
                                listVersion 
                                branch={proposal.inquiry.branch_spec.branch}
                            />
						</CardBody>
					</Card>
				</Col>
			</Row>
        </>
    )
}