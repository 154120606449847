import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { Descriptions, Divider, Empty, Popconfirm } from 'antd'

import ReviewSecurityModal from './partials/modals/ReviewSecurityModal'
import ReviewResultsModal from './partials/modals/ReviewResultsModal'
import NewServiceReviewModal from './partials/modals/NewServiceReviewModal'

import ServiceReviewSpecsList from '../ReviewSpecs/ServiceReviewSpecsList'
import ReviewEquipmentsTab from '../ReviewEquipments/ReviewEquipmentsTab'
import ReviewImpartialityNoticeModal from '../ImpartialityNotice/ReviewImpartialityNoticeModal'
import ImpartialityNoticeAttachmentModal from '../ImpartialityNotice/partials/ImpartialityNoticeAttachmentModal'
import EmailLogsModal from '../EmailLogs/EmailLogsModal'
import EditReviewModal from './partials/modals/EditReviewModal'

import SendNotificationButton from '../../components/SendNotificationButton'

import { deleteServiceReview, notifyServiceReview } from './services'
import { can, handleError, handleSuccess, text } from '../../helpers'

const { Item } = Descriptions

export default function ServiceReviewTab({ service, reload }) {
	const { isConnected } = useSelector(state => state.offline)
	const [showNewModal, setShowNewModal] = useState(false)
	const [showSecurityModal, setShowSecurityModal] = useState(false)
	const [showResultsModal, setShowResultsModal] = useState(false)
	const [showImpartialityNoticeModal, setShowImpartialityNoticeModal] = useState(false)
	const [showImpartialityAttachmentModal, setShowImpartialityAttachmentModal] = useState(false)
	const [emailLogsModal, setEmailLogsModal] = useState(null)
	const [showEditModal, setShowEditModal] = useState(false)

	const serviceReview = service.service_inspector.service_plan.service_review || null
	const verificationList = service.verification_list

	const handleDelete = () => {
		can('service_reviews.delete') && deleteServiceReview(serviceReview.id)
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

	return (
		<>
			<h6 className='mt-10 mb-0 text-center'>Acta de Inspección</h6>
			{ serviceReview ? (
				<>
					<p className='bold pt-10'>INFORMACIÓN DE SEGURIDAD</p>
					<Descriptions bordered column={2} size='small' className='mb-15'>
						<Item label="1. ¿Conoce las acciones que se deben adelantar en caso de emergencia?" span={2}>
							{ parseInt(serviceReview.review_specs.has_security_actions) ? 'Si' : 'No' }
						</Item>
						<Item label="2. ¿Se cuenta con las condiciones necesarias para realizar la inspección de manera segura?" span={2}>
							{ parseInt(serviceReview.review_specs.has_security_conditions) ? 'Si' : 'No' }
						</Item>
						<Item label="3. ¿Requiere de algún elemento adicional para realizar la inspección de forma segura?	" span={2}>
							{ parseInt(serviceReview.review_specs.has_security_additionals) ? 'Si' : 'No' }
						</Item>
						{ service.business_scope === 'pruebas' && (
							<Item label="4. ¿Existe soporte técnico con recomendación por parte del fabricante sobre el procedimiento de prueba que se debe utilizar?" span={2}>
								{ parseInt(serviceReview.review_specs.has_tecnical_support) ? 'Si' : 'No' }
							</Item>
						)}
					</Descriptions>
					{ service.is_editable && (
						<div className='text-right mb-10'>
							<p className='text-link inline' onClick={() => setShowSecurityModal(true)}>
								Modificar respuestas y observaciones de seguridad
							</p>
						</div>
					)}
					{ service.is_editable && (
						<div className='text-right mb-20'>
							<p className='text-link inline' onClick={() => setShowEditModal(true)}>
								Modificar persona que recibe la inspección
							</p>
						</div>
					)}
					{ service.business_scope === 'pruebas' && (
						<>
							<Descriptions bordered column={2} size='small' className='mb-15'>
								<Item label="Fecha de Inicio" style={{ whiteSpace:'pre-line' }}>
									{text(serviceReview.review_specs.start_at)}
								</Item>
								<Item label="Fecha Finalización" style={{ whiteSpace:'pre-line' }}>
									{text(serviceReview.review_specs.end_at)}
								</Item>
								<Item label="Resultados con Cumplimiento" span={2} style={{ whiteSpace:'pre-line' }}>
									{serviceReview.review_specs.results_if_compliant }
								</Item>
								<Item label="Resultados sin Cumplimiento" span={2} style={{ whiteSpace:'pre-line' }}>
									{serviceReview.review_specs.results_if_not_compliant }
								</Item>
								<Item label="Declaración Finalización del Servicio" span={2} style={{ whiteSpace:'pre-line' }}>
									{serviceReview.review_specs.final_declaration }
								</Item>
							</Descriptions>
							{ service.is_editable && (
								<div className='text-right mb-20'>
									<p className='text-link inline' onClick={() => setShowResultsModal(true)}>
										Modificar respuestas de resultados
									</p>
								</div>
							)}
						</>
					)}
					<p className='bold mt-10'>CARACTERÍSTICAS TÉCNICAS DE LA INSTALACIÓN</p>
					{ service.business_scope === 'inspeccion' && (
						<ServiceReviewSpecsList service={service} serviceReviewId={serviceReview.id} />
					)}					
					<p className='bold mt-10'>EQUIPOS DE MEDICIÓN EMPLEADOS EN LA INSPECCIÓN</p>
					<ReviewEquipmentsTab
						serviceReview={serviceReview}
						reload={reload}
					/>
					<p className='bold mt-10'>RATIFICACIÓN DEL PRINCIPIO DE IMPARCIALIDAD E INDEPENDENCIA</p>
					<Descriptions bordered column={2} size='small' className='mb-15'>
						<Item style={{ wordBreak: 'normal' }} label="Si la instalación ha recibido servicios por alguna de las entidades relacionadas identificadas previamente por  DEWAR, escriba el nombre y diligencie la Ratificación del principio de imparcialidad e independencia FRN34">
							{serviceReview.impartiality_notice ? serviceReview.impartiality_notice.inspector_name : 'NA'}
						</Item>
					</Descriptions>
					{ service.is_editable && (
						<div className='text-right mb-20'>
							<p className='text-link inline' onClick={() => setShowImpartialityNoticeModal(true)}>
								Modificar ratificación de imparcialidad
							</p>
						</div>
					)}
					{ serviceReview.impartiality_notice && isConnected && (
						<>
							<div className='text-right mb-20'>
								<p className='text-link' onClick={() => setShowImpartialityAttachmentModal(serviceReview.impartiality_notice)}>
									Adjunto de Formato de Ratificación
								</p>
							</div>
							<div className='text-right mb-20'>
								<Link to={`/impartiality_notices/preview/${serviceReview.id}`} target="_blank">
									Ver Formato de Ratificación
								</Link>
							</div>
						</>
					)}
					<Link to={isConnected ? `/service_reviews/preview/${serviceReview.id}` : `/offline/service_reviews/preview/${service.id}/${serviceReview.id}`}>
						<Button color="primary" outline>Formato de Acta de Inspección</Button>
					</Link>
					<Divider type="vertical" />
					<SendNotificationButton 
						notifyService={()=>notifyServiceReview(serviceReview.id)} 
						offlineRequest={{method: 'POST', url: `/service_reviews/${serviceReview.id}/notify`}}
					/>
					<Divider type="vertical" />
					<Button color="primary" outline onClick={() => setEmailLogsModal(serviceReview.id)} disabled={!isConnected}>
						Correos Enviados
					</Button>
					{ !verificationList && (
						<>
							<Divider type="vertical" />
							<Popconfirm
								title="Esta acción no se puede reversar"
								okText="Eliminar"
								cancelText="Cancelar"
								okButtonProps={{ danger:true }}
								onConfirm={handleDelete}
							>
								<Button color="primary" outline>Eliminar Acta de Inspección</Button>
							</Popconfirm>
						</>
					)}
				</>
			) : (
				<Empty description="No se tiene una acta de inspección aún" className='pt-50'>
					<Button color="primary" onClick={() => setShowNewModal(true)}>
						Iniciar Acta de Inspección
					</Button>
				</Empty>
			)}
			{ showNewModal && (
				<NewServiceReviewModal 
					visible
					onClose={() => setShowNewModal(false)}
					servicePlanId={service.service_inspector.service_plan.id}
					businessScope={service.business_scope}
					reload={reload}
				/>
			)}
			{ showSecurityModal && (
				<ReviewSecurityModal 
					visible
					onClose={() => setShowSecurityModal(false)}
					serviceReview={serviceReview}
					serviceId={service.id}
					businessScope={service.business_scope}
					reload={reload}
				/>
			)}
			{ showResultsModal && (
				<ReviewResultsModal 
					visible
					onClose={() => setShowResultsModal(false)}
					serviceReview={serviceReview}
					serviceId={service.id}
					businessScope={service.business_scope}
					reload={reload}
				/>
			)}
			{ showImpartialityNoticeModal && (
				<ReviewImpartialityNoticeModal 
					visible
					onClose={() => setShowImpartialityNoticeModal(false)}
					serviceReview={serviceReview}
					serviceId={service.id}
					reload={reload}
				/>
			)}
			{ showImpartialityAttachmentModal && (
				<ImpartialityNoticeAttachmentModal 
					visible
					onClose={() => setShowImpartialityAttachmentModal(null)}
					impartialityNotice={showImpartialityAttachmentModal}
					reload={reload}
				/>
			)}
			{ emailLogsModal && (
				<EmailLogsModal
					visible
					onClose={() => setEmailLogsModal(null)}
					modelType="service_review"
					modelId={emailLogsModal}
				/>
			)}
			{ showEditModal && (
				<EditReviewModal 
					visible
					onClose={() => setShowEditModal(false)}
					serviceReview={serviceReview}
					reload={reload}
					service={service}
					fromServiceProfile
				/>
			)}
		</>
	)
}