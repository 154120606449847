import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'

import PreInvoiceForm from './PreInvoiceForm'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { storePreInvoice } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'
import { getProposal } from '../../Proposals/services'

export default function NewPreInvoiceModal({ visible, onClose, proposalId }) {
	const [proposal, setProposal] = useState(null)
	const [taxesFactor, setTaxesFactor] = useState(0.19)
	const [loading, setLoading] = useState(false)
	const { register, handleSubmit, formState: { errors, isDirty }, watch, setValue } = useForm({
		defaultValues: { value: 0, taxes: 0, quntity: 1 }
	})
	const history = useHistory()

	useEffect(() => {
		!proposal && can('pre_invoices.create') && getProposal(proposalId)
			.then(res => setProposal(res.data.data))
			.catch(error => handleError(error))
	}, [ proposal, proposalId ])

	const onSubmit = values => {
		setLoading(true)
		values.taxes = ((parseInt(values.service_value)*parseInt(values.quantity))+parseInt(values.trip_expenses_value)) * taxesFactor
		storePreInvoice({ ...values, proposal_id: proposalId })
			.then(res => handleSuccess(res.data.message, () => history.push(`/pre_invoices/preview/${res.data.data.id}`)))
			.catch(error => handleError(error, setLoading))
	}

	if(!can('pre_invoices.create'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para crear cuentas de cobro" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Agregar Cuenta de Cobro</ModalHeader>
				<ModalBody>
					{ proposal ? (
						<Form onSubmit={handleSubmit(onSubmit)}>
							<PreInvoiceForm 
								register={register} 
								errors={errors}
								watch={watch}
								setValue={setValue}
								isDirty={isDirty}
								taxesFactor={taxesFactor}
								setTaxesFactor={setTaxesFactor}
								proposal={proposal}
							/>
							<Button color='primary' type="submit" disabled={loading}>
								{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
								Enviar Cuenta de Cobro
							</Button>
						</Form>
					) : (
						<Spinner animation='border' size="sm" />
					)}
				</ModalBody>
			</Modal>
		</>
	)
}