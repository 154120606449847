import React from 'react'
import { useForm } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import TankForm from './TankForm'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { updateTank } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function EditTankModal({ visible, onClose, tank, reload }) {
	const { register, handleSubmit, formState: { errors }, reset } = useForm()

	const onSubmit = values => {
		updateTank(tank.id, values)
			.then(res => handleSuccess(res.data.message, reload, handleClose))
			.catch(error => handleError(error))
	}

	const handleClose = () => {
		reset()
		onClose()
	}

	if(!can('tanks.update'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar tanques" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible} toggle={handleClose}>
				<ModalHeader toggle={handleClose}>Actualizar Tanque</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<TankForm
							tank={tank} 
							register={register}
							errors={errors}
						/>
						<Button type="submit" color="primary">Actualizar</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}