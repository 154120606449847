import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Spinner } from 'react-bootstrap'
import { Descriptions } from 'antd'

import EditReviewLogbookModal from './EditReviewLogbookModal'
import ReviewLogbookWellTestsTable from './tables/ReviewLogbookWellTestsTable'
import ReviewLogbookSurveyAnswersTable from './tables/ReviewLogbookSurveyAnswersTable'
import ReviewLogbookTightnessTestsTable from './tables/ReviewLogbookTightnessTestsTable'
import ReviewLogbookSpecDetailsTable from './tables/ReviewLogbookSpecDetailsTable'
import ReviewLogbookAttachments from './ReviewLogbookAttachments'

import { getReviewLogbook } from '../services'
import { handleError, text } from '../../../helpers'

const { Item } = Descriptions

export default function ReviewLogbookDetailsModal({ visible, onClose, defaultReviewLogbook, serviceId }) {
	const { isConnected } = useSelector(state => state.offline)
    const [reviewLogbook, setReviewLogbook] = useState(null)
    const [editModalKey, setEditModalKey] = useState(null)

    useEffect(() => {
        if(isConnected){
            !reviewLogbook && visible && getReviewLogbook(defaultReviewLogbook.id)
                .then(res => setReviewLogbook(res.data.data))
                .catch(error => handleError(error))
        } else {
            setReviewLogbook(defaultReviewLogbook)
        }
    }, [ reviewLogbook, defaultReviewLogbook, visible, isConnected ])

    if(!reviewLogbook) 
        return <Modal isOpen={visible} size="xl"><ModalBody><Spinner animation="border" /></ModalBody></Modal>

    return (
        <>
            <Modal isOpen={visible} size="xl">
                <ModalHeader toggle={onClose}>
                    [BIT00{reviewLogbook.id}] Bitacora de Inspección - {reviewLogbook.spec_type === 'tank' ? 'Tanque' : 'Tubería'} {reviewLogbook.spec_category_slug} #{reviewLogbook.spec_number}
                </ModalHeader>
                <ModalBody>
                    <p className='bold'>IDENTIFICACIÓN DE {reviewLogbook.spec_type === 'tank' ? 'TANQUE' : 'TUBERÍA'}</p>
                    <ReviewLogbookSpecDetailsTable reviewLogbook={reviewLogbook} />
                    <p className='text-right text-link' onClick={() => setEditModalKey('specDetails')}>
                        Modificar Detalles {reviewLogbook.spec_type === 'tank' ? 'Tanque' : 'Tubería'}
                    </p>
                    <p className='bold'>PREGUNTAS INSTALACIÓN DE EQUIPOS</p>
                    <ReviewLogbookSurveyAnswersTable 
                        reviewLogbook={reviewLogbook}
                    />
                    <p className='text-right text-link' onClick={() => setEditModalKey('surveyAnswers')}>
                        Modificar Respuestas Registradas
                    </p>
                    { reviewLogbook.spec_category_slug === 'subterraneo' && (
                        <>
                            <p className='bold'>PRUEBAS DE POZOS</p>
                            <ReviewLogbookWellTestsTable 
                                reviewLogbook={reviewLogbook} 
                                reload={() => setReviewLogbook(null)}
                            />
                            <p className='text-right text-link' onClick={() => setEditModalKey('wellsTests')}>
                                Agregar Prueba de Pozos
                            </p>
                        </>
                    )}
                    <p className='bold'>PRUEBAS DE VERIFICACION DE LA HERMETICIDAD</p>
                    <ReviewLogbookTightnessTestsTable 
                        reviewLogbook={reviewLogbook}
                    />
                    <p className='text-right text-link' onClick={() => setEditModalKey('tightnessTests')}>
                        Modificar Pruebas de Hermeticidad
                    </p>
                    <p className='bold'>LISTA DE VERIFICACIÓN</p>
                    <Descriptions bordered size='small' column={1}>
                        <Item label={reviewLogbook.spec_type === 'tank' ? '4.1 El tanque inspeccionado alcanza la presión mínima recomendada por el fabricante o 2.5 – 3.0 psi de acuerdo con la disposición relacionada en la Resolución 40198' : '4.1  La tubería inspeccionada alcanza la presión mínima recomendada por el fabricante ó la establecida por el procedimiento'}>
                            {text(reviewLogbook.requirements_survey.item_0410)}
                        </Item>
                        <Item label={reviewLogbook.spec_type === 'tank' ? '4.2 El tanque inspeccionado mantiene durante el procedimiento de la prueba la presión mínima recomendada por el fabricante o 2.5 – 3.0 psi de acuerdo con la disposición relacionada en la Resolución 40198 de 2021' : '4.2  La tubería inspeccionada mantiene durante el procedimiento de la prueba la presión mínima recomendada por el fabricante ó la establecida por el procedimiento'}>
                            {text(reviewLogbook.requirements_survey.item_0420)}
                        </Item>
                    </Descriptions>
                    <p className='text-right text-link mt-20' onClick={() => setEditModalKey('requirementsSurvey')}>
                        Modificar Lista de Verificación
                    </p>
                    { isConnected && (
                        <ReviewLogbookAttachments 
                            reviewLogbook={reviewLogbook} 
                            onClose={onClose} 
                            reload={() => setReviewLogbook(null)}
                        />
                    )}
                </ModalBody>
            </Modal>
            { editModalKey && (
                <EditReviewLogbookModal 
                    visible
                    onClose={() => setEditModalKey(false)}
                    reviewLogbook={reviewLogbook}
                    serviceId={serviceId}
                    reload={() => setReviewLogbook(null)}
                    formKey={editModalKey}
                />
            )}
        </>
    )
}