import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { syncRolesUser } from '../services'
import { getRoles } from '../../Roles/services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function EditUserRolesModal({ visible, onClose, user, reload }) {
    const [roles, setRoles] = useState(null)
    const { register, handleSubmit } = useForm({
        defaultValues: {
            roles: user.roles
        }
    })

    useEffect(() => {
        !roles && can('users.update') && getRoles()
            .then(res => setRoles(res.data.data))
            .catch(error => handleError(error))
    }, [roles])

    const onSubmit = values => {
        if(values.roles.length === 0)
            return message.warning('Debe seleccionar al menos un rol')

        syncRolesUser(user.id, values)
            .then(res => handleSuccess(res.data.message, reload, onClose))
            .catch(error => handleError(error))
    }

    if(!can('users.update'))
        return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar usuarios" onClose={onClose} />

    return (
        <Modal isOpen={visible}>
            <ModalHeader toggle={onClose}>Editar Roles del Usuario</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    { roles ? (
                        <>
                            { roles.map(role => (
                                <Form.Check
                                    key={role.id}
                                    type="checkbox"
                                    label={role.name}
                                    value={role.name}
                                    {...register('roles[]')}
                                />
                            )) }
                        </>
                    ) : (
                        <Spinner animation='border' size="sm" />
                    )}
                    <Button color="primary" type="submit">
                        Asignar Roles
                    </Button>
                </Form>
            </ModalBody>
        </Modal>
    )
}