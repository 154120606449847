import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { Alert, message } from 'antd'
import moment from 'moment'

import DepartmentAndCityForm from '../../../components/DepartmentAndCityForm'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { storeDownloadToken } from '../../DownloadTokens/services'
import { getUri } from '../../../services/dewarApi'
import { can, handleError, serialize } from '../../../helpers'

export default function DownloadReportModal({ 
	visible, onClose, data
}) {
	const user_id = useSelector(state => state.auth.user.id)
	const [loading, setLoading] = useState(false)
	const { register, handleSubmit, formState: { errors }, watch } = useForm({
		defaultValues: {
			from: moment().subtract('1', 'month').format('YYYY-MM-DD'),
			to: moment().format('YYYY-MM-DD')
		}
	})

	const onSubmit = values => {
		setLoading(true)
		let token = new Date().getTime()
		storeDownloadToken({ type: `Descarga de ${data.title}`, token, user_id })
			.then(() => {
				message.info('Generando reporte en nueva pestaña')
				window.open(`${getUri()}/reports/export/${data.slug}/${token}?${serialize(values)}`)
				onClose()
			})
			.catch(error => handleError(error))
			.finally(() => setLoading(false))
	}

	if(!can('download_tokens.create'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para descargar reportes" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Descargar {data.title}</ModalHeader>
				<ModalBody>
					<Alert type="info" description="Al descargar el informe se exportara un archivo excel del mismo." />
					<Form onSubmit={handleSubmit(onSubmit)} className='mt-10'>
						{ data.datesRangeFilter && (
							<Form.Group>
								<Form.Label>Rango de Fechas <span className='text-danger'>*</span></Form.Label>
								<Row>
									<Col>
										<Form.Control type="date" {...register('from', { required: true })} />
									</Col>
									<Col>
										<Form.Control type="date" {...register('to', { required: true })} />
									</Col>
								</Row>
								{ (errors.from || errors.to) && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
							</Form.Group>
						)}
						{ data.demograficsFilter && (
							<DepartmentAndCityForm 
								register={register}
								errors={errors}
								watch={watch}
								isRequired={false}
							/>
						)}
						{ data.businessScopeFilter && (
							<Form.Group>
								<Form.Label>Tipo de Negocio <span className='small'>(opcional)</span></Form.Label>
								<Form.Control
									as="select"
									{...register('business_scope', { })}
								>
									<option value="">:: Todos los tipos ::</option>
									<option value="inspeccion">Inspección</option>
									<option value="pruebas">Pruebas</option>
								</Form.Control>
							</Form.Group>
						)}
						{ data.extraScopesFilter && (
							<Form.Group>
								<Form.Label>Estado <span className='small'>(opcional)</span></Form.Label>
								<Form.Control
									as="select"
									{...register('extra_scope', { })}
								>
									<option value="">:: Todos los estados ::</option>
									{ data.extraScopesFilter.map(scope => (
										<option key={scope.value} value={scope.value}>{scope.label}</option>
									))}
								</Form.Control>
							</Form.Group>
						)}
						<Button className='mt-10' color="primary" type="submit" disabled={loading}>
							Descargar{" "}
							{ loading && <Spinner size="sm" className='ml-5' /> }
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}