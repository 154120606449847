import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import ProposalAcceptanceForm from '../forms/ProposalAcceptanceForm'
import ReplaceAcceptanceForm from '../forms/ReplaceAcceptanceForm'
import PermissionIsRequired from '../../../../components/PermissionIsRequired'

import { can } from '../../../../helpers'

export default function ProposalAcceptanceModal({ visible, onClose, proposal, reload }) {

	if(!can('proposals.update'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar propuestas" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>
					Aceptación Propuesta {proposal.sku}
				</ModalHeader>
				<ModalBody>
					{ !proposal.accepted_at ? (
						<ProposalAcceptanceForm 
							proposal={proposal} 
							reload={reload}
						/>
					) : (
						<ReplaceAcceptanceForm 
							proposal={proposal} 
							reload={reload}
						/>
					)}
				</ModalBody>
			</Modal>
		</>
	)
}