import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'

import PaymentReceiptForm from './PaymentReceiptForm'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

import { storePaymentReceipt } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'

export default function NewPaymentReceiptModal({ visible, onClose, proposalId, reload }) {
	const [attachmentUri, setAttachmentUri] = useState(null)
	const { register, handleSubmit } = useForm({})
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		storePaymentReceipt({ 
			...values, 
			proposal_id: proposalId, 
			attachment_uri: attachmentUri 
		})
		.then(res => handleSuccess(res.data.message, reload, onClose))
			.catch(error => handleError(error, setLoading))
	}

	if(!can('payment_receipts.create'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para crear soportes de pagos" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Agregar Soporte de Pago</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<PaymentReceiptForm 
							register={register} 
							proposalId={proposalId}
							setAttachmentUri={setAttachmentUri}
						/>
						<Button color='primary' type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Guardar Soporte de Pago
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}