import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faIndustry, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditEmployeeModal from './EditEmployeeModal'
import EmployeeBranchesModal from '../../Branches/EmployeeBranchesModal'

import { deleteEmployee } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

export default function EmployeesTable({ employees, reload }) {
	const currentUser = useSelector(state => state.auth.user)
	const [editEmployee, setEditEmployee] = useState(null)
	const [employeeBranches, setEmployeeBranches] = useState(null)

	const handleDelete = id => {
		deleteEmployee(id)
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}
	
	const columns = [
		{
			title: 'Nombre',
			dataIndex: 'name',
		},
		{
			title: 'Cargo',
			dataIndex: 'position',
		},
		{
			title: 'Correo',
			dataIndex: 'email',
			render: t => t ? t : <em className='hint-text'>Sin email registrado</em>
		},
		{
			title: 'Acciones',
			width: '105px',
			render: (t, record) => (
				<>
					<Tooltip title="Editar">
						<div className='inline' onClick={()=>setEditEmployee(record)}>
							<FontAwesomeIcon className="text-link" icon={faEdit} />{" "}
						</div>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Sedes Asociadas">
						<div className='inline' onClick={()=>setEmployeeBranches(record)}>
							<FontAwesomeIcon className="text-link" icon={faIndustry} />{" "}
						</div>
					</Tooltip>
					{ currentUser.is_super_admin && (
						<>
							<Divider type="vertical" />
							<Popconfirm 
								title="¿Desea eliminar el empleado?"
								okText="Eliminar"
								cancelText="Cancelar"
								onConfirm={() => handleDelete(record.id)}
								okButtonProps={{ danger:true }}
							>
								<FontAwesomeIcon className="text-link" icon={faTrash} />{" "}
							</Popconfirm>
						</>
					)}
				</>
			)
		}
	]

	const expandedRow = r => (
		<>
			<p className='mb-10'><strong>Celular:</strong> {r.mobile ? r.mobile : <em className='hint-text'>Sin celular registrado</em>}</p>
			<p className='mb-0'><strong>Fijo:</strong> {r.phone ? r.phone : <em className='hint-text'>Sin teléfono fijo registrado</em>}</p>
		</>
	)

	if(!can('employees.viewAll'))
		return <PermissionIsRequired title='No tiene permisos para ver listado de personal' />
	
	return (
		<>
			<Table 
				rowKey={r => r.id}
				dataSource={employees}
				size='small'
				columns={columns}
				expandable={{ expandedRowRender: expandedRow }}
			/>
			{ editEmployee && (
				<EditEmployeeModal 
					visible
					onClose={() => setEditEmployee(null)}
					employee={editEmployee}
					reload={reload}
				/>
			)}
			{ employeeBranches && (
				<EmployeeBranchesModal
					visible
					onClose={() => setEmployeeBranches(null)}
					employee={employeeBranches}
				/>
			)}
		</>
	)
}