import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form } from 'react-bootstrap'
import moment from 'moment'

import EditCertificateForm from '../forms/EditCertificateForm'
import PermissionIsRequired from '../../../../components/PermissionIsRequired'

import { updateCertificate } from '../../services'
import { can, handleError, handleSuccess } from '../../../../helpers'

export default function EditCertificateModal({ visible, onClose, certificate, reload }) {
	const [countModification, setCountModification] = useState(true)
	const { register, handleSubmit, formState: { errors } } = useForm()

	const onSubmit = values => {
		let modifications = countModification ? {
			modifications_count: parseInt(certificate.modifications_count) + 1,
			modified_at: moment().format('YYYY-MM-DD'),
		} : {}

		if(certificate.sku.toString() === values.sku) delete values.sku

		updateCertificate(certificate.id, { ...modifications, ...values })
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

	if(!can('certificates.update'))
		return <PermissionIsRequired type="modal" title="No tiene permisos para actualizar certificados" onClose={onClose} />

	return (
		<>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Actualizar Certificado de Inspección</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<EditCertificateForm 
							register={register}
							errors={errors}
							certificate={certificate}
							countModification={countModification}
							setCountModification={setCountModification}
						/>
						<Button color="primary" type="submit">Modificar Certificado</Button>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}