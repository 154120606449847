import React from 'react'
import { useSelector } from 'react-redux'
import { Badge } from 'reactstrap'
import { Popconfirm, Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn, faUnlink } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import { updateFormatVersion } from '../services'
import { can, handleError, handleSuccess } from '../../../helpers'
import PermissionIsRequired from '../../../components/PermissionIsRequired'

export default function FormatVersionsTable({ formatVersions, reload }) {
	const currentUser = useSelector(state => state.auth.user)

	const handleToggle = (id, disabledAt) => {
		updateFormatVersion(id, { disabled_at: disabledAt })
			.then(res => handleSuccess(res.data.message, reload))
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Versión',
			dataIndex: 'sku'
		},
		{
			title: 'Formato',
			dataIndex: 'keyword',
			render: t => {
				let words = t.split('(')
				return words[0]
			}
		},
		{
			title: 'Fecha Vigencia',
			dataIndex: 'expire_at',
			render: t => moment(t).format('DD-MMM-YYYY').toUpperCase()
		},
		{
			title: 'Unidad de Negocio',
			dataIndex: 'business_scope',
			render: t => `Servicio de ${t}`
		},
		{
			title: 'Estado',
			dataIndex: 'disabled_at',
			render: t => t ? <Badge color='dark'>Desactivado</Badge> : <Badge color='success'>Activo</Badge>
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<>
					{ currentUser.is_super_admin && (
						<>
							{ !record.disabled_at ? (
								<Popconfirm 
									title="¿Desea desactivar la version?"
									okText="Desactivar"
									cancelText="Cancelar"
									onConfirm={() => handleToggle(record.id, moment().format('YYYY-MM-DD HH:mm:ss'))}
									okButtonProps={{ danger:true }}
								>
									<FontAwesomeIcon className="text-link" icon={faUnlink} />{" "}
									<span className='text-link'>Desactivar</span>
								</Popconfirm>
							) : (
								<Popconfirm 
									title="¿Desea activar la version?"
									okText="Activar"
									cancelText="Cancelar"
									onConfirm={() => handleToggle(record.id, '')}
								>
									<FontAwesomeIcon className="text-link" icon={faToggleOn} />{" "}
									<span className='text-link'>Activar</span>
								</Popconfirm>
							)}
						</>
					)}
				</>
			)
		}
	]

	if(!can('format_versions.viewAll'))
		return <PermissionIsRequired title="No tiene permisos para ver las versiones de formato" />

	return (
		<>
			<Table 
				dataSource={formatVersions}
				columns={columns}
				rowKey={record => record.id}
				size="small"
			/>
		</>
	)
}